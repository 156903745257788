const playlists = {
  'one': {
    slug: 'playlistKi',
    title: 'playlistKi',
    songs: [ 
['Hey Minnale', 'HINDI', '2017', 'Pritam', 'DE3cet2ZI8k', 'Jab Harry Met Sejal'],
['Chuttamalle', 'HINDI', '2017', 'Pritam', '5vsOv_bcnhs', 'Jab Harry Met Sejal'],
['Aasa kooda', 'HINDI', '2017', 'Pritam', 'a3Ue-LN5B9U', 'Jab Harry Met Sejal'],
['All The Stars', 'HINDI', '2017', 'Pritam', 'ju4KQT0wL0I', 'Jab Harry Met Sejal'],
['Come back Indian', 'HINDI', '2017', 'Pritam', 'z8NeHpgAong', 'Jab Harry Met Sejal'],
['Where is my mind cover orchestra', 'HINDI', '2017', 'Pritam', 'eg66Y6ySNUs', 'Jab Harry Met Sejal'],
['Lost Art', 'HINDI', '2017', 'Pritam', 'Jk1lfH7OG74', 'Jab Harry Met Sejal'],
['Oasis Whatever live', 'HINDI', '2017', 'Pritam', 'g3qAsP-fiF4', 'Jab Harry Met Sejal'],
['Where is my mind cover', 'HINDI', '2017', 'Pritam', 'ZLE3uUnXjd8', 'Jab Harry Met Sejal'],
['Kaathuvaakula', 'HINDI', '2017', 'Pritam', 'YlymYnShOLw', 'Jab Harry Met Sejal'],
['Stay', 'HINDI', '2017', 'Pritam', 'yWHrYNP6j4k', 'Jab Harry Met Sejal'],
['Saware Single', 'HINDI', '2017', 'Pritam', 'KurLNA6ZId4', 'Jab Harry Met Sejal'],
['Kannazhaga X Nee Paartha', 'ENGLISH', '-', 'Billy Joel', 'vaauxPRRp-U', '-'],
['Pareshaan', 'ENGLISH', '-', 'Billy Joel', 'zQl7zYkEP6M', '-'],
['Challeya', 'ENGLISH', '-', 'Billy Joel', 'wr9M-CoxP7A', '-'],
['Nadhiye Nadhiye', 'TAMIL', '2000', 'AR RAHMAN', '_80XRBvl5-c', 'Rhythm', 348, 400],
['Eastside cover', 'ENGLISH', '2018', 'Benny Blanco', 'Dn_RpdGMgpE', 'Eastside'],
['Munbe Vaa IndoSoul cover', 'TAMIL', '2019', 'A. R. Rahman', 'XwpzrHuwrUQ', 'Sillunu Oru Kaadhal', 0, 346],
['Symphony Cover', 'HINDI', '2007', 'Pritam', '_Pz3hjTJBFA', 'Awarapan'],
['Pathu Thala BGM ', 'TAMIL', '2015', 'Harris Jayaraj', 'Ed2LWmAsKMU', 'Anegan'],
['Uppena BGM 1', 'TAMIL', '2015', 'Harris Jayaraj', 'H9AJxUqhzK8 1', 'Anegan', 0, 52],
['Main Agar', 'HINDI', '2017', 'Pritam', 'foJMOAGcncQ', 'Tubelight', 0, 277],
['swarnakamalam| Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'MeWTN3e7Usk', '-'],
['Trinity: titoli', 'ENGLISH', '1970', 'Annibale E I Cantori Moderni', 'IQPGNh_2vtw', 'Lo chiamavano Trinità', 0, 180],
['House Of The Rising Sun', 'ENGLISH', '-', 'Billy Joel', 'sHbKdI26RkE', '-'],
['Calling Panimalar', 'TAMIL', '2013', 'AR Rahman', 'KR8O_6seAh8 2', 'Maryan', 798, 884],
['Maate vinadhu ga', 'TELUGU', '2018', 'Jakes Bejoy', 'KMocA8G_puU', 'Taxiwaala', 4, 275],
['Can You Hear The Music orchestra', 'ENGLISH', '-', 'Billy Joel', 'YEbsbQtTvHs', '-'],
['Ranjha', 'PUNJABI', '1996', 'A.R Rahman', 'UU-eUEt-tPg', 'Bharateeyudu'],
['Chris Isaak - Cant Help Falling In Love ', 'HINDI', '2017', 'Pritam', 'UJ8RBj_P0KQ', 'Jab Harry Met Sejal'],
['My Life Be Like', 'ENGLISH', '2002', 'Grits', '83mhHOytg4U', 'The Art of Translation'],
['Suno Na Sangemarmar', 'ENGLISH', '1985', 'Tears for Fears', '83pr5QLz6Bc', 'Songs from the Big Chair'],
['Love me little', 'HINDI', '2007', 'Vishal-Shekhar', 'Z6rEHTLBGeg', 'Om Shanti Om'],
['Black Sheep', 'ENGLISH', '2010', 'Metric', '8pJZ9dWvxyA', ' Scott Pilgrim vs. the World'],
['Am I Wrong - LIVE', 'ENGLISH', '2013', 'Nico & Vinz', 'lKceHnMhwgQ', 'Am I Wrong', 0, 302],
['Pehli Dafa', 'ENGLISH', '-', 'Billy Joel', '70FbfOQXPwk', '-'],
['Anegan Love Theme BGM', 'TAMIL', '2015', 'Harris Jayaraj', 'caAYmk8sHA4', 'Anegan'],
['Uppena BGM 2', 'TAMIL', '2015', 'Harris Jayaraj', 'H9AJxUqhzK8 2', 'Anegan', 381, 447],
['Stay the night piano', 'TAMIL', '2015', 'Harris Jayaraj', 'niBXg0MWhVo', 'Anegan'],
['Master Intro', 'TAMIL', '2015', 'Harris Jayaraj', 'FlpMc54hEt0', 'Anegan'],
['Saware cover', 'HINDI', '2015', 'Pritam', '8eQuOTRsqW4', 'Phantom'],
['Dhanush Intro BGM', 'TAMIL', '2015', 'Harris Jayaraj', '92uJ6sDNQ8s', 'Anegan'],
['Nee Gaali Taake Haayiki ', 'TAMIL', '2015', 'Harris Jayaraj', 'pF7cv5IhyWk', 'Anegan'],
['Nee Singam Dhaan', 'HINDI', '2014', 'A.R Rahman', 'o-sTXv52z48', ' Highway'],
['Humraah', 'HINDI', '2007', 'Vishal-Shekhar', 'Y8ETNh_IQjs', 'Om Shanti Om', 0, 295],
['O Re Piya', 'HINDI', '2007', 'Salim–Sulaiman', 'JyvX1LkNXz8', 'Aaja Nachle'],
['Can you hear the music', 'ENGLISH', '-', 'Billy Joel', '4JZ-o3iAJv4', '-'],
['Swag Se Swagat', 'HINDI', '2017', 'Vishal-Shekhar', 'xmU0s2QtaEY', 'Tiger Zinda Hai', 0, 156],
['Chumma Kizhi', 'TAMIL', '2020', 'Anirudh ', 'FdVuB1G2G_g', 'Darbar', 2, 228],
['Yung Gravy - Betty', 'TAMIL', '2005', 'Harris Jayaraj', 'O6h0hmsTAyY', 'Ghajini'],
['Adhento Gaani', 'TELUGU', '2019', 'Anirudh', '-fapo-nHzxw', 'Jersey', 0, 234],
['Wherever you go', 'TAMIL', '2015', 'Harris Jayaraj', 'c17VN0xlLV0', 'Anegan'],
['Jeevakaasham', 'TELUGU', '2007', 'Devi Sri Prasad', 'Hqge4UiuX1Q', 'Shankardada Zindabad'],
['Here Comes The Sun', 'HINDI', '2017', 'Pritam', 'KQetemT1sWc', 'Jab Harry Met Sejal'],
['Mujhe Mujhse Kaun', 'TELUGU', '2007', 'Devi Sri Prasad', 'OPe_grZSbbo', 'Shankardada Zindabad'],
['Symphony Nobel Concert', 'ENGLISH', '2000', 'Hans Zimmer ', 'KLx7X2vcPIA', 'Gladiator'],
['Channa Mereya', 'HINDI', '2016', 'Pritam', 'bzSTpdcs-EI', 'Ae Dil Hai Mushkil', 0, 285],
['Backbone', 'PUNJABI', '1996', 'A.R Rahman', 'zJWeMC1q4ac', 'Bharateeyudu'],
['2U', 'ENGLISH', '2017', 'Justin Bieber', '3QAOqZPJgZE', '2U', 0, 186],
['Stay the night', 'TELUGU', '2018', 'Vishal Chandrasekhar', 'BfVY9-tejX4', 'Padi Padi Leche Manasu'],
['Uppena BGM 3', 'TAMIL', '2015', 'Harris Jayaraj', 'H9AJxUqhzK8 3', 'Anegan', 584, 620],
['Happy Intro BGM', 'TELUGU', '2006', 'Yuvan Shankar Raja', '4rW0QU7c3Lw', 'Happy', 0, 34],
['Shayad RAAG Cover', 'HINDI', '2020', 'Pritam', 'Eqj4MHPgpPI', 'Love Aaj Kal 2020', 0],
['phir na milen kabhi', 'HINDI', '2007', 'Vishal-Shekhar', 'Powg9XIWNpU', 'Om Shanti Om', 0, 295],
['Sakhiyan', 'PUNJABI', '1996', 'A.R Rahman', 'S-ezhTXPVGU', 'Bharateeyudu', 10],
['Apna bana le', 'ENGLISH', '2000', 'Hans Zimmer ', 'u2NAuswnTKs', 'Gladiator'],
['2U cover', 'HINDI', '2007', 'Pritam', 'Eomw2013KMo', 'Awarapan'],
['Chitti Aduga', 'HINDI', '2007', 'Pritam', 'qD8sPWTekDQ', 'Awarapan'],
['O Re Piya  flute', 'HINDI', '2007', 'Pritam', 'k_ZRVM3aYZc', 'Awarapan'],
['O Rendu Prema Meghaalila', 'TELUGU', '1996', 'A R Rahman', 'D_SRMiIWyL4', 'Prema Desam'],
['Final Ascent', 'ENGLISH', '2008', 'Hans Zimmer ', 'Vv_z2obBFd0', 'No Time To Die', 240],
['Ilamai Thirumbudhe', 'TAMIL', '2019', 'Anirudh', '3QKgnA4uy30', 'Petta', 0, 212],
['SarangaDariya', 'HINDI', '2017', 'Pritam', 'L0TJAYSVZX0', 'Jab Harry Met Sejal'],
['Yaen Ennai Pirindhaai', 'TAMIL', '2019', 'Radhan', 'Dfy5S93chgM', 'Adithya varma', 0, 195],
['Zingat', 'MARATHI', '2016', 'Ajay-Atul', '8eong8XHDB4', 'Sairat', 0, 225],
['TRUCKS IN PLACE', 'ENGLISH', '-', 'Ludwig Göransson', 'NaSiqlwkK7U', '-'],
['Bullet wali', 'TAMIL', '2008', 'A. R. Rahman', 'NpVDVw-OlgY', 'Sakkarakatti'],
['Shaamat', 'TELUGU', '1996', 'A R Rahman', 'd_Xbd2I5Zxk', 'Prema Desam'],
['Eastside cover', 'ENGLISH', '2018', 'Benny Blanco', 'tybBI5tuAtc', 'Eastside'],
['Neeye Oli', 'TAMIL', '2020', 'Santhosh Narayanan', 'e_0Q92a8Wlo', 'Sarpatta Parambarai', 0, 240],
['Dil Ye Bekarar Kyun Hai', 'HINDI', '2012', 'Pritam', '5oKFcSeHTSU', ' Players'],
['I want it that way', ' ENGLISH', '2007', ' Backstreet Boys', 'LwkrXybZ1uo', 'Millennium', 0, 210],
['Halamithi Habibo', 'ENGLISH', '1985', 'Tears for Fears', '8FAUEv_E_xQ', 'Songs from the Big Chair'],
['Vachindamma', 'TELUGU', '2018', 'Gopi Sunder', 'xVcoYF--0mM', 'Geetha Govindam', 4, 240],
['The Day Of (Oceans Twelve)', 'TELUGU', '1996', 'A R Rahman', 'GnOKz3FYAPo', 'Prema Desam'],
['The Karma Theme', 'TELUGU', '2018', 'Anirudh', '1himtoWeA_U', 'U Turn', 7, 225],
['ALLAH VE', 'HINDI', '2019', 'Jassie Gill', '2E4h4KuULag', 'Allah Ve'],
['Chellamma', 'TAMIL', '2006', 'AR RAHMAN', 'CF732miQJ50', 'Rang De Basanti'],
['Sucker', 'ENGLISH', '2019', 'Jonas Brothers', 'Ty0WEJvlljs', 'Happiness Begins', 0, 179],
['Hand Cannot Erase', 'ENGLISH', '1998', 'Steven Wilson', '7JFdk6zEcWc', '-'],
['Etthara Jenda', 'ENGLISH', '1985', '-', 'E1FFN5yK8Eo', '-'],
['Zombie Orchestral', 'HINDI', '2009', 'Pritam', 'j6aldFFePLw', 'Billu', 0, 278],
['Chitta Kukkad', 'HINDI', '2017', 'Pritam', 'Yzp1jT-4F20', 'Jab Harry Met Sejal'],
['Arrival of the Birds', 'TELUGU', '1996', 'A R Rahman', 'H9gFLkNdHvA', 'Prema Desam'],
['Mona Gasolina Cover', 'TAMIL', '2014', 'A. R. Rahman', '99FMOcFP7-A', 'Lingaa'],
['Makhna', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'xNn1vcZ51vg', 'Pirates Of The Caribbean'],
['STAY Acoustic', 'TAMIL', '2008', 'A. R. Rahman', '2cityFMf_UE', 'Sakkarakatti'],
['THATS WHAT I WANT', 'PUNJABI', '1996', 'A.R Rahman', 'zHd4rSpZn48', 'Bharateeyudu'],
['Rain Fight', 'TAMIL', '2018', 'Santhosh Narayanan', 'rc2ft794XNI', 'Kaala', 5, 275],
['Leja Re', 'PUNJABI', '1996', 'A.R Rahman', '-xCw7ruotUg', 'Bharateeyudu'],
['Tera Ghata', 'ENGLISH', '-', 'Billy Joel', 'mEMHF5W4zlY', '-'],
['Dekhte Dekhte', 'HINDI', '2018', 'Rochak Kohli', 'jBQpGiubj0c', 'Batti Gul Meter Chalu', 0, 254],
['Priya Priyathama', 'TELUGU', '1991', 'Ilaiyaraaja', 'Dr9Dbef84HI', 'Killer', 0, 307],
['Symphony Orchestra', 'TAMIL', '2008', 'A. R. Rahman', 'qS4bCBx_07I', 'Sakkarakatti'],
['O Saathi', 'HINDI', '2018', 'Arko', 'YuXLN23ZGQo', 'Baaghi 2', 0, 248],
['Teenmaar BGM', 'TELUGU', '2011', 'Mani Sharma', 'BZXVyiOGMKM', 'Teenmaar'],
['Chogada', 'HINDI', '2018', 'Lijo George - Dj Chetas', 'd4OGZZ0EsAI', 'Loveyatri'],
['Venpani Malare', 'TAMIL', '2017', 'Sean Roldan', '7KvLlA4g1so', '  Power Paandi'],
['Tera Hua', 'HINDI', '2018', 'Tanishk Bagichi', '-4JV4hur1PM', 'Loveyatri', 0, 210],
['Levitating', 'ENGLISH', '2020', 'Dua Lipa', 'TUVcZfQe-Kw', '-'],
['Cold Water (Diwali)', 'ENGLISH', '2016', 'Major Lazer', 'vIm218Mhn9A', ' Cold Water', 0, 216],
['Chitta', 'TELUGU', '2005', 'Mahesh Shankar', '4ENIIs46heE', 'Vennela'],
['Teri Ore', 'HINDI', '2008', 'Pritam', 'CWfCp96-yck', 'Singh Is Kinng'],
['Vennele Kurisenule', 'ENGLISH', '2015', 'Coldplay', 'XWmsQZUZxIA', 'A Head Full of Dreams'],
['Kesariya (Dance Mix)', 'ENGLISH', '-', 'Billy Joel', 'K3B8-klo5xc', '-'],
['Akhiyaan Milavanga', 'TELUGU', '2001', 'Mani Sharma', 'O9zBPcU5g60', 'Kushi'],
['Eastside acousticc', 'ENGLISH', '2018', 'Benny Blanco', '2C-zNQq7zRQ', 'Eastside'],
['Sunn Raha Hai (Female) ', 'HINDI', '2013', 'Ankit Tiwari', '49Yo4_9zNQ8', ' Aashiqui 2', 0, 312],
['Malang', 'HINDI', '2007', 'Vishal-Shekhar', 'd4EWhSD11Dg', 'Om Shanti Om'],
["IT AIN'T ME ", 'ENGLISH', '2017', 'Selena Gomez and Kygo', 'D5drYkLiLI8', 'Stargazing', 0, 216],
['Shayad', 'HINDI', '2020', 'Pritam', 'MJyKN-8UncM', 'Love Aaj Kal 2020', 12, 186],
['Tur Kalleyan', 'TAMIL', '2015', 'Harris Jayaraj', 'eu49PSLU2B4', 'Anegan'],
['Baba Oriley', 'ENGLISH', '-', 'Billy Joel', 'QRTNm6GLJYI', '-'],
['Saware', 'HINDI', '2015', 'Pritam', 'CsOsmgUmT9U', 'Phantom', 0, 317],
['Shayad 2', 'HINDI', '2020', 'Pritam', 'vGHa_VcAIxM', 'Love Aaj Kal 2020'],
['Sarvam Thaala Mayam', 'TAMIL', '2019', 'A.R Rahman', 'd3OZVsHG9TM', 'Sarvam Thaala Mayam', 0, 280],
['Sau Aasoon', 'HINDI', '2015', 'Shankar-Ehsaan-Loy', 'fdkLuhr6u0U', 'Katti Batti', 0, 335],
['Zindagi ne Zindagi Bhar Gham Diye', 'HINDI', '2007', 'Mithoon', 'i-xRii7pL_o', 'The Train', 0, 324],
['Kadavulae Vidai', 'TAMIL', '2017', 'Anirudh ', 'Hfh_dcVaN5o', 'Rum', 0, 240],
['Judaai', 'HINDI', '2011', 'Sachin-Jigar', '5Dnv-nIlwWo', 'Badlapur', 0, 268],
['Tareefan', 'HINDI', '2018', 'Qaran', 'RcbdKqBESz8', 'Veere Di Wedding', 0, 182],
['Dil toh bacha hai ji - remix', 'HINDI', '2010', 'Vishal Bhardwaj', '8AZBJRpuOsU', 'Ishqiya'],
['Golden Brown', 'TAMIL', '2008', 'A. R. Rahman', 'Qca9ByvLhBA', 'Sakkarakatti'],
['Saware cover', 'HINDI', '2015', 'Pritam', '5kONNn8Fclw', 'Phantom'],
['Livin it', 'TAMIL', '1999', 'A R Rahman', 'WlgoUlOkyzU', 'Padayappa'],
['Canon in D Maj', 'ENGLISH', '-', 'Billy Joel', '8Af372EQLck', '-'],
['Let her go feat Ed Sheeran', 'ENGLISH', '-', 'Billy Joel', 'HTcL9WkB_wg', '-'],
['NEwyork Nagaram sarangi', 'ENGLISH', '-', 'Billy Joel', 'ezu5N0uGilc', '-'],
['Va vaathi Dhanush', 'ENGLISH', '-', 'Billy Joel', '8s2DQBhEvoA', '-'],
['Kanyakumari', 'ENGLISH', '-', 'Billy Joel', 'q6I3D4R04yQ', '-'],
['Moongil Thottom cover', 'ENGLISH', '-', 'Billy Joel', 'VglLMoTIAVs', '-'],
['Maruthaani sarangi', 'ENGLISH', '-', 'Billy Joel', 'D1h4zIN_k6U', '-'],
['Mood', 'ENGLISH', '-', 'Billy Joel', 'nMVFSwfV6wk', '-'],
['The Tornados - Telstar', 'ENGLISH', '-', 'Billy Joel', 'WPDvsLSnUGc', '-'],
['Mattilanti Nannu', 'TELUGU', '2004', 'Joshua Sridhar', '_-JdC_Gccco', 'Premisthe', 0, 374],
['Moongil Thottam', 'TAMIL', '2013', 'A.R Rahman', 'IOA4-EIhtLA', 'Kadal', 0, 270],
['Yemito Song BGM', 'TELUGU', '2012', 'Radhan', 'umo5yXRLUn4', 'Andala Rakshasi', 10],
['International Love', 'ENGLISH', '2012', 'Pitbull - Chris Brown', 'mxTuDbJt3VA', '-', 0, 222],
['Clarity', 'TAMIL', '2008', 'A. R. Rahman', 'wpQ_R85VfJc', 'Sakkarakatti'],
['Thangamey', 'TAMIL', '2015', 'Anirudh', 'i0OxPAPoIm0', 'Naanum Rowdy Dhaan'],
['My Love', 'ENGLISH', '2000', 'Westlife', 'btUTQeto940', 'Coast to Coast', 0, 230],
['Was He Slow', 'TAMIL', '2005', 'Harris Jayaraj', '3igDZrG3DTQ', 'Ghajini'],
['What Goes Around', 'ENGLISH', '2013', 'Justin Timberlake', 'qKBMyal0ILA', '-'],
['Moongil Thottam BGM', 'TAMIL', '2013', 'A.R Rahman', 'w73UGUpemU0', 'Kadal'],
['Chinna Chinna Aasai', 'TAMIL', '1992', 'A R Rahman', 'Qi4yrJ88ZTE', 'Roja'],
["Hips Don't Lie", 'ENGLISH', '2005', 'Shakira-Wyclef Jean', 'vtb0csLelDs', 'Oral Fixation Vol. 2'],
['Here without you', 'ENGLISH', '2002', '3 Doors Down', 'NxBl_q_NDas', 'Away from the Sun', 0, 233],
['Boom Boom', 'TAMIL', '2003', 'A R Rahman', 'APrsYxNkgt4', 'Boys', 0, 300],
['Wo Ajnabee', 'TELUGU', '1998', 'Deva', 'dWHIcMeAIMw', 'Tholiprema'],
['Ghajini BGM 8', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 43', 'Ghajini', 1153, 1225],
['Sahana', 'TAMIL', '2007', 'AR RAHMAN', 'oGhHgXiMVAI', 'Sivaji'],
['Ilankaathu Veesuthe', 'TAMIL', '2003', 'Ilaiyaraaja', 'zkrSm_8aeWk', 'Pithamagan'],
['E rathale', 'TELUGU', '2005', 'Mahesh Shankar', 'vHuBCcm7KC8', 'Vennela'],
['Happier', 'ENGLISH', '2018', 'Marshmello', 'm7Bc3pLyij0', 'Happier', 0, 223],
['Sweety', 'TELUGU', '2014', 'S. Thaman', 'FqfQVtbClQM', 'Race Gurram', 0, 262],
['Dilko Tumse Pyar Hua', 'HINDI', '2001', 'Harris Jayaraj ', 'ZiJgkQLunUM', 'Rehna Hai Tere Dil Mein', 0, 328],
['I Took A Pill In Ibiza', 'ENGLISH', '2011', 'One Direction', 'u3VFzuUiTGw', 'Up All Night'],
['ROCK THA PARTY', 'HINDI', '2016', 'BOMBAY ROCKERS', 'areBR3RVBVc', 'ROCKY HANDSOME', 26],
['Montages Music Bit', 'TELUGU', '2002', 'Harris Jayaraj', 'H9jczk_LA8g', 'Vaasu', 0, 290],
['Sinne Sinne Korikaladaga', 'TELUGU', '1987', 'Ramesh Naidu', 'rqIoB1cMvUM', 'Swayam Krushi', 0, 274],
['DOORIYAN', 'HINDI', '2009', 'Pritam', 'Zl2qSYFWiv8', 'Love Aaj Kal'],
['Nenjukkule', 'TAMIL', '2013', 'A.R Rahman', 'zUcZKys9qc4', 'Kadal'],
['Naan Un Azhaginile BGM 2', 'TAMIL', '2015', 'A R Rahman', 'k7UNnJYB8Ho 1', '24', 226, 305],
['Ok Jaanu', 'HINDI', '2010', 'A R Rahman', '_ZQ9e3NFENQ', '-'],
['Danza Kudoro Remix', 'HINDI', '2006', 'A. R. Rahman', 'uTWi2mLrntk', 'Rang De Basanti'],
['Chikku Bukku', 'TAMIL', '1993', 'A R Rahman', 'Lx4dhNHg6uQ', 'Gentleman'],
['Battle Without Honor', 'HINDI', '2017', 'Pritam', 'gw5vAd5icAg', 'Jab Harry Met Sejal'],
['Swapnavevedo', 'TELUGU', '1999', 'Mani Sharma', 'SU38X4FBAhI', 'Ravoyi Chandamama'],
['Kohinoor', 'HINDI', '2019', 'Divine', '8q_eV_RErE4', 'Kohinoor'],
['TaareGinn-Variant ', 'HINDI', '2005', 'A. R. Rahman', 'f9Ek79iZ05M', 'Dil Bechara'],
['Main Theme [Rain Man]', 'ENGLISH', '2008', 'Hans Zimmer ', 'RIrbQkiHgWI', ''],
['Arjun Reddy bgm 3', 'TELUGU', '2017', 'Radhan', 'KpKQTAW-3K0 3', 'Arjun Reddy', 569, 625],
['Andala akasamanta', 'TELUGU', '2005', 'Vidyasagar', 'Rp6ivvw89y8', 'Chandramukhi', 0, 268],
['Ada', 'HINDI', '2005', 'Pritam', 'N9IGKvBgTO8', 'Garam Masala', 4, 283],
['BOYS BGM', 'TAMIL', '2003', 'A R Rahman', 'WiNcKZIiwu8', 'Boys', 0],
['vidhata talapuna Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'CmF8_mAN83M', '-'],
['O Priya Priya BGM', 'TELUGU', '1989', 'IlayaRaaja', '2EOG-4I8Deo', 'Geethanjali', 0],
['Innum Konjam Naeram (BGM - All Versions)', 'TAMIL', '2013', 'AR Rahman', 'C_7hVebC9mY', 'Maryan'],
['Om Mangalam', 'HINDI', '2010', 'Pritam', 'pW6EHVJ78K4', 'Once Upon a Time in Mumbaai'],
['7G Brundavan Colony BGM 1', 'TELUGU', '2007', 'Yuvan Shankar Raja', 'i7aSm_lG4yA 1', '7G Brundavan Colony', 0, 42],
['Tere Liye', 'HINDI', '2010', 'Sachin Gupta', 'AlvUuGJccKs', 'Prince', 8, 281],
['Africa', 'ENGLISH', '1982', 'Toto', 'FTQbiNvZqaY', 'Toto IV'],
['Naani Koni', 'TAMIL', '2012', 'Harris Jayaraj', 'MamzbVp3m8M', 'Maattrraan', 0, 324],
['Ey Hairathe', 'HINDI', '2007', 'A. R. Rahman', '038jmlSH3iM', 'Guru', 0, 361],
['Pareshaan', 'HINDI', '2012', 'Amit Trivedi', 'k5TRnHHQ57U', 'Ishaqzaade'],
['Galliyan BGM', 'HINDI', '2014', 'Ankit Tiwari', 'OJF5yvGHeck', ' Ek Villain'],
['Evare Nuvvu', 'TELUGU', '2007', 'Yuvan Shankar Raja', 'CcPXolTwGoI', 'Raju Bhai', 0, 246],
['Matargasthi flute', 'HINDI', '2015', 'A R Rahman', 'vx7PMO_pGc4', '-'],
['Time ', 'ENGLISH', '-', 'Hans Zimmer ', 'jgyShFzdB_Q', 'Inception'],
['Allukonava', 'TELUGU', '2005', 'Vidyasagar', 'd86hdqZvNm0', 'Majaa', 0, 282],
['Writings on the wall', 'HINDI', '2017', 'Pritam', '-8uwoW_mt_Ek', 'Jab Harry Met Sejal'],
['All of me', 'TAMIL', '2008', 'A. R. Rahman', 'ngq5Aw0Q6rQ', 'Sakkarakatti'],
['Secret of Success ', 'TAMIL', '2003', 'AR RAHMAN', 'kjOrdWcybpg', 'Boys', 0, 365],
['Don’u Don’u Don’u', 'TAMIL', '2015', 'Anirudh', 'Y0eErsVNK-I', 'Maari', 0, 194],
['The Dewarists : I Believe', 'HINDI', '2011', 'Agnee + Parikrama + Shilpa Rao', 'o9Nq2Ww80zE', 4, 337],
['Lose Yourself', 'ENGLISH', '2002', 'Eminem', 'ZjB-sz62tJ8', '8 Mile', 0, 324],
['Whatever', 'ENGLISH', '1994', 'Oasis', 'cAa4BN_Uadc', 'Definitely Maybe', 0, 360],
['LOVE MERA HIT HIT', 'HINDI', '2009', 'Pritam', 'o6ojv9uUezU', 'Billu'],
['Saree Ke Fall Sa', 'HINDI', '2006', 'Shankar-Ehsaan-Loy', 'jJsFYt3JvRU', 'Kabhi Alvida Naa Kehna'],
['Cant Help Falling In Love (feat. Josh Turner)', 'ENGLISH', '1985', 'Tears for Fears', 'j5mmhokV24Q', '-'],
['Minnale BGM 6', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 6', 'Minnale', 1822, 1892],
['Oh Oh', 'TAMIL', '2015', 'Anirudh ', 'mSwGIp24CqQ', 'Thanga Magan'],
['Jaamurathiri', 'TELUGU', '1991', 'M.M.Keeravani', 'GJBZc_djj60', 'Kshana Kshanam', 0, 288],
['Yeto Vellipoyindi', 'TELUGU', '1996', 'Sandeep Chawtha', 'SFOdFjq2NSg', 'Ninne Pelladatha', 0, 273],
['Bin Tere', 'HINDI', '2010', 'Vishal-Shekhar', '5IX-nUxDtJI', 'I Hate Luv Storys', 0, 328],
['Soniya Telugu', 'TELUGU', '1997', 'AR RAHMAN', '3iw-dH9nqFo', 'Rakshakudu', 5, 335],
['Padaiyappa', 'TAMIL', '1999', 'A. R. Rahman', 'aEF-VG3ePp0', 'Padaiyappa', 0, 319],
['Idhazhin Oram', 'TAMIL', '2012', 'Anirudh ', 'lZORMUufA_Y', '3'],
['Uppena BGM-A', 'TELUGU', '2020', 'Devi Sri Prasad', '9i87gLga508', 'Uppena', 3, 47],
['Pehli Nazar Mein', 'HINDI', '2008', 'Pritam', 'swcCuuQKGJ4', ' Race'],
['Okkadu Flute BGM', 'TELUGU', '2003', 'Mani Sharma', 'djbyPS0dwkY', 'Okkadu', 0, 32],
['Naa Pranama', 'TELUGU', '2001', 'S.A.Raj Kumar', 'dqcC56cqyiY', 'Daddy', 0, 292],
['On My Way', 'ENGLISH', '2015', 'Alan Walker', 'fm-nXA-K0Dg', '-'],
['Idhedho Bagundhe', 'TELUGU', '2013', 'Devi Sri Prasad', 'QL-I0SSjZO0', 'Mirchi', 0, 266],
['Send My Love', 'HINDI', '2017', 'Pritam', '76ZMZJguS0g', 'Jab Harry Met Sejal'],
['21 Guns', 'ENGLISH', '2009', 'Green Day', 'w29aq_sgwxw', '21 Guns'],
['Kabhi Neem Neem', 'HINDI', '2004', 'AR RAHMAN', 'ALQ7MLtEwtI', 'Yuva', 0, 292],
['Udhungada Sangu', 'TAMIL', '2014', 'Anirudh ', 'RCXzH27eOIA', 'Velai Illa Pattadhaari'],
['Ke Ghar Kab Aaoge', 'HINDI', '1997', 'Anu Malik', 'OunpPA0cYdM', 'Border', 0, 625],
['Kaatu Mooliyo', 'MALAYALAM', '2015', 'Shaan Rahman', 'vilA6jt5w6Y', 'Ohm Shanthi Oshaana'],
['Kannukkul Kannai', 'TAMIL', '2010', 'A R Rahman', '4ZHKtLuQpGk', 'Vinnaithaandi Varuvaayaa', 0, 230],
['Deivangal Ingae', 'TAMIL', '2015', 'Harris Jayaraj', 'K5yJ7eVMRUA', 'Anegan', 0, 204],
['Nee Kannu Neeli Samudram', 'TELUGU', '2020', 'Devi Sri Prasad', 'mrqgz4_a4PU', 'Uppena'],
['Tchê tcherere tchê tchê', 'Portuguese', '2011', 'Gusttavo Lima', 'jtCZSEhd1Xo', 'Balada'],
['Hai Junoon', 'TELUGU', '2018', 'Vishal Chandrasekhar', '_hEgNwyHlAU', 'Padi Padi Leche Manasu'],
['Khoon Chala', 'TAMIL', '2006', 'AR RAHMAN', 'ezfrbmJuLBM', 'Rang De Basanti', 0, 186],
['Mudhalvan Love Bgm', 'TAMIL', '2004', 'A. R. Rahman', 'iLgUVzpbYXU', ''],
['James Brown, 2Pac - Unchained', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 't5uAX0pyD_I', 'Pirates Of The Caribbean'],
['Take On Me ', 'ENGLISH', '2011', 'One Direction', 'Ktb44YLL8Nw', 'Up All Night'],
['Karuppi', 'TAMIL', '2018', 'Santhosh Narayanan', '5IXdCWhQG78', 'Pariyerum Perumal', 0, 241],
['Aye Sinamika', 'TAMIL', '2015', 'A. R. Rahman', 'nj5X_9AHh_k', 'OK Kanmani'],
['Demons', 'ENGLISH', '2012', 'Imagine Dragons', 'mWRsgZuwf_8', 'Continued Silence EP', 0, 189],
['Pookal Pookum', 'TAMIL', '2010', 'GV Prakash', 'meocisSQzkQ', 'Madrasapattinam', 0, 394],
['Please Sir', 'TAMIL', '2003', 'A R Rahman', 'RKuT8WXhhTM', 'Boys'],
['Everything I Need', 'ENGLISH', '2018', 'Skylar Grey', 'wZXGtN-VZQo', 'Aquaman'],
['Hall of fame', 'ENGLISH', '2012', 'The Script', '3Kxf2dHlDpQ', 'Hall of Fame'],
['Agar Tum Saath Ho', 'HINDI', '2015', 'A R Rahman', 'sK7riqg2mr4', 'Tamasha', 0, 336],
['Falak Tak', 'HINDI', '2008', 'Vishal-Shekhar', 'PfcwlfCm7fc', 'Tashan', 0, 350],
['Have You Ever Seen The Rain', 'ENGLISH', '1970', 'Creedence Clearwater Revived', 'g4flAZEgtjs', 'Pendulum', 0, 156],
['AADAT', 'HINDI', '2007', 'JAL', '8FzeqBVF8ms', 'AADAT', 0, 266],
['Never Gonna Give You Up', 'ENGLISH', '2005', 'Rick Astley', '34Ig3X59_qA', 'Oral Fixation Vol. 2'],
['MARJAANI', 'HINDI', '2009', 'Pritam', 'xYyV1ZXLVdA', 'Billu', 0, 321],
['Padayappa Theme 1', 'TAMIL', '1999', 'A R Rahman', 'eguXwwDSQNM 1', 'Padayappa', 0, 44],
['Miserlou', 'HINDI', '2017', 'Pritam', 'mKpsuGMeqHI', 'Jab Harry Met Sejal'],
['Glad You Came', 'ENGLISH', '2012', ' The Wanted', 'XsMvL1uvl_o', 'The Wanted', 0, 190],
['Aha Allari', 'TELUGU', '2002', ' Devi Sri Prasad', '5sqMIH_F5ks', 'Kadgam', 16, 270],
['Noonday Sun', 'MISC', '1997', 'Deep Forest', '89w0y7x67tc', 'Comparsa'],
['Tere Bina', 'HINDI', '2007', 'A. R. Rahman', '7HKbt19q3Rc', 'Guru'],
['Mallikale', 'TAMIL', '1996', 'AR RAHMAN', 'BB6hjWrHRHQ', 'Mr Romeo'],
['Rather Be', 'ENGLISH', '2014', 'Clean Bandit Jess Glynne', 'jUjDcqdvCrk', 'New Eyes'],
['Oh Shanthi Shanthi', 'TAMIL', '2008', 'Harris Jayaraj', 'jnxTNyxBHRk', 'Vaaranam Aayiram'],
['Padayappa Theme 2', 'TAMIL', '1999', 'A R Rahman', 'eguXwwDSQNM 2', 'Padayappa', 644, 694],
['Bhagam Bhag', 'HINDI', '2006', 'Pritam', 'llUw1AZ5ubI', 'Bhagam Bhag', 0, 275],
['Neekosam oka', 'TELUGU', '2006', 'Yuvan Shankar Raja', 'JuQucW4cJ4Y', 'Happy', 0, 197],
['Titanium', 'ENGLISH', '2011', 'Sia - David Guetta', 'JRfuAukYTKg', 'Nothing but the Beat', 0, 240],
['Uppena BGM', 'TELUGU', '2020', 'Devi Sri Prasad', 'NEGvG70tI08', 'Uppena', 3, 115],
['Ponmagal Vandaal', 'TAMIL', '2013', 'AR Rahman', '21QM9yzSz4g', '-'],
['Pirates', 'ENGLISH', '1985', 'Tears for Fears', 'dfgJYRdNwDE', 'Songs from the Big Chair'],
['Bheegi Si Bhaagi Si', 'HINDI', '2007', 'Pritam', 'WP8KWkt4SbI', 'Rajniti', 0, 100],
['Tujh Mein Rab Dikhta Hai', 'HINDI', '2008', 'Salim-Sulaiman', 'Vq8lbcb0Lcw', 'Rab Ne Bana Di Jodi', 0, 273],
['Kaalaiyil dhinamum bgm 1', 'TAMIL', '2004', 'A. R. Rahman', 'T0wQS4O0dOU', 'New', 0, 62],
['Premalekhanu Raasenu', 'TELUGU', '2010', 'Salim–Sulaiman', 'yk4n-KXFj2E', 'Anaganaga Oka Dheerudu', 3, 256],
['Samajavaragamana Cover', 'TELUGU', '2019', 'S. Thaman', 'guj4nJFvLo4', 'Ala Vaikunthapurramloo'],
['Shewolf', 'TAMIL', '2008', 'A. R. Rahman', 'vYOUBrRI_aM', 'Sakkarakatti'],
['Galliyan Unplugged', 'HINDI', '2017', 'Pritam', 'cfc9d4LA1eE', 'Jab Harry Met Sejal'],
['Naanum BGM 1', 'TAMIL', '2015', 'Anirudh', 'A9H6UGsG3c4', 'Naanum Rowdy Dhaan'],
['Thuli Thuli', 'TAMIL', '2010', 'Yuvan Shankar Raja', 'rHweW6dRRYY', 'Paiya', 0, 280],
['The Saltwater Room', 'ENGLISH', '2008', 'Adam Young', 'qMp3TIVYwwk', 'Maybe', 'I am Dreaming'],
['Khalbali', 'ENGLISH', '2016', 'Sia', 'TWHdwAWhcfs', 'This Is Acting'],
['Tum Se Hi', 'HINDI', '2007', 'Pritam', 'Cb6wuzOurPc', ' Jab We Met', 0, 317],
['Zombie', 'ENGLISH', '1994', 'The Cranberries', 'bZFbX19Q-R8', 'No Need to Argue', 0, 305],
['Gal Mitthi Mitthi', 'HINDI', '2010', ' Amit Trivedi', 'iKs0WgXjTKI', 'Aisha', 0, 253],
['They Dont Care About Us', 'ENGLISH', '2010', 'Michael Jackson - Akon', 'GsHZBisKwxg', 'Jackson’s Paradise'],
['Anegan BGM', 'TAMIL', '2015', 'Harris Jayaraj', 'pqjykOVy-L4', 'Anegan'],
['Enna solla pogirai BGM', 'TAMIL', '2000', 'A. R. Rahman', '3b6I9jq-rJQ', 'Kandukonden kandukonden'],
['Ok Anesa', 'TELUGU', '2008', 'Mickey J. Meyer', '7saoh3n0fjY', 'Kothabangarulokam'],
['Varai', 'TELUGU', '2005', 'Vidyasagar', '1cs0GfHCw00', 'Chandramukhi'],
['Kanulu Therichina', 'TELUGU', '2001', 'Devi Sri Prasad', 'BXkdXtwFd_0', 'Aanandham'],
['O Priya Priya', 'TELUGU', '1989', 'IlayaRaaja', 'nkkC4PpxCCU', 'Geethanjali', 5, 323],
['Teri Deewani', 'HINDI', '2016', 'Kailash Kher', 'F_F6yC87-ts', 'Best of Bollywood: Sufi', 0, 320],
['Stereo Love', 'ENGLISH', '2009', 'Edward Maya - Vika Jigulina', 'sw5kcRjBkEY', 'Stereo Love', 0, 246],
['Numb / Encore', 'ENGLISH', '2003', 'Linkin Park', 'sCRRRofQ4tI', 'Meteora'],
['Stereo Hearts', 'ENGLISH', '2011', 'Gym Class Heroes', 'T3E9Wjbq44E', 'The Papercut Chronicles II'],
['Perfect EMMA HEESTERS & KHS COVER', 'ENGLISH', '2017', 'Ed Sheeran', 'w-uPPc6Ffek', 'Divide', 0, 208],
['Pyaar Karke Pachtaya', 'HINDI', '2007', 'Pritam', 'mayceSObuRk', 'Pyaar Ke Side Effects'],
['Numb', 'ENGLISH', '2003', 'Linkin Park', '8P0vKLHbtMg', 'Meteora'],
['Ghajini BGM 11', 'TAMIL', '2005', 'Harris Jayaraj', 'Mrt3PYz3g0c', 'Ghajini', 610, 683],
['The Greatest ', 'HINDI', '2017', 'Pritam', 'IzCpyiabtME', 'Jab Harry Met Sejal'],
['Sundari Kannaal oru Sethi', 'ENGLISH', '1992', 'Thaikkudam Bridge', '_mgEITE5tn8', 'Thaikkudam Bridge', 0, 192],
['Interstellar Cover', 'TAMIL', '1999', 'A R Rahman', 'BFetvsemGOY', 'Padayappa'],
['Manikya Malaraya', 'MALAYALAM', '2018', 'Shaan Rahman', 'W0fKl43QmIE', 'Oru Adaar Love', 0, 190],
['Chop Suey', 'ENGLISH', '2001', 'System Of A Down', 'clUkF4z6Q14', 'Toxicity'],
['Whataya Want From Me', 'ENGLISH', '2009', 'Adam Lambert', 'Rd78eThTuNM', 'For Your Entertainment', 0, 224],
['VIP BGM 2', 'TAMIL', '2014', 'Anirudh ', '5J6RWxPNOiE', 'Velai Illa Pattadhaari', 816, 864],
['The Way I Are', 'ENGLISH', '2016', 'Timbaland', 'zJjDZOebZb0', '-'],
['Goruvanka Valaga', 'TELUGU', '1994', 'M.M.Keeravani', 'LkiuAtiTlyM', 'Gandeevam', 0, 300],
['Gajini lovely bgm', 'TAMIL', '2005', 'Harris Jayaraj', '0Pnqbi7XwUw', 'Ghajini'],
['Tamasha BGM', 'HINDI', '2015', 'A R Rahman', '9dDBHEu5wuY', 'Tamasha', 0, 56],
['Deham Thiri', 'TELUGU', '2004', 'AR RAHMAN', '8Tk6GAavNG0', 'Yuva', 0, 260],
['Oh Prema', 'TELUGU', '2001', 'Mani Sharma', 'x0X2ld8YZlM', 'Devi Putrudu', 0, 310],
['Cheppave Chirugali', 'TELUGU', '2003', 'Mani Sharma', 'DX9u4QEPy0g', 'Okkadu', 16, 345],
['Thittam Poda Theriyala', 'ENGLISH', '2010', 'Charlie Puth', 'cQ_wKOpz6rQ', 'See You Again'],
['Now we are free cover', 'HINDI', '2009', 'Pritam', 'v20_fStXSYU', 'Billu'],
['Rockabye', 'ENGLISH', '2017', 'Clean Bandit', '2VDdP7lYiiI', 'Rockabye (Autograf Remix)'],
['Hay Rey Hai', 'TELUGU', '2003', 'Mani Sharma', 'CcYZTrEe4TE', 'Okkadu', 0, 302],
['Elastic Heart', 'ENGLISH', '2005', 'Bon Jovi', '5RYY0hwHIRw', 'Oral Fixation Vol. 2'],
['Nammaku Nammaku', 'TELUGU', '1988', 'IlayaRaaja', 'SkqRFfBvjWE', 'Rudra Veena', 70, 346],
['Ke ke lunga ', 'TELUGU', '2007', 'Devi Sri Prasad', 'iy46sv82YSg', 'Shankardada Zindabad'],
['No Time for Caution', 'ENGLISH', '2014', 'Hans Zimmer', '2o9KfyPqQzY', 'Interstellar', 0, 241],
['Promontory - The Last of the Mohicans', 'ENGLISH', '1992', 'Trevor Jones-Randy Edelman', 'pNU7YM05TCY', 'The Last of the Mohicans'],
['Naa Hrudayam', 'TELUGU', '2004', 'Devi Sri Prasad', 'MsymghOuogg', 'Yamudu', 0, 270],
['Ghajini BGM 3', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 3', 'Ghajini', 884, 905],
['Ayyayo', 'TAMIL', '2011', 'G.V.Prakash Kumar', 'uxb-TMoqb9k', 'Aadukalam', 0, 318],
['Memories', 'ENGLISH', '2019', 'Maroon 5', 'o2DXt11SMNI', 'Memories'],
['Butta Bomma', 'TELUGU', '2019', 'S. Thaman', 'YO7rue3nKX0', 'Ala Vaikunthapurramloo'],
['Pehla Pehla Pyaar', 'ENGLISH', '2000', 'Hans Zimmer ', 'YNuqXDGE5rw', 'Gladiator'],
['Yeppadi Irruntha', 'TELUGU', '2007', 'Devi Sri Prasad', 'Y5FP5pdile4', 'Shankardada Zindabad'],
['Wake Me Up', ' ENGLISH', '2013', 'Avicii', 'SsYXnH9lzCY', 'True'],
['Hasili Fisiliye', 'TELUGU', '2009', 'Harris Jayaraj ', 'Q6cm9h5yWvs', 'Aadhavan', 0, 316],
['Luka Chuppi', 'HINDI', '2006', 'A. R. Rahman', '_ikZtcgAMxo', 'Rang De Basanti', 0, 390],
['Khiladi Koona', 'TELUGU', '2007', 'Mani Sharma', 'dZie1jzCAfE', 'Athidhi', 0, 254],
['The Godfather slash cover', 'HINDI', '2017', 'Pritam', '-vBImmzSxH0', 'Jab Harry Met Sejal'],
['Zara Zara', 'HINDI', '2001', 'Harris Jayaraj ', 'XcsAyUrMsuk', 'Rehna Hai Tere Dil Mein'],
['Gale Lag Ja', 'HINDI', '2009', 'Pritam', 'VwAAtZp3XZ8', 'De Dana Dan', 0, 240],
['Sillunu Oru Kadal Bit', 'TAMIL', '2013', 'AR Rahman', 'KR8O_6seAh8 1', 'Maryan', 884],
['Kiss Me Baby', 'HINDI', '2005', 'Pritam', 'E0RtZVm78dk', 'Garam Masala', 2, 219],
['Gaali Vaaluga', 'TELUGU', '2017', 'Anirudh', 'd9ul2C4f3CU', 'Agnyaathavaasi', 0, 254],
['Whenever Wherever', 'ENGLISH', '2001', 'Shakira', 'cZhli2OT5Rg', 'Laundry Service', 0, 196],
['Chak De', 'HINDI', '2007', 'Salim–Sulaiman', 'OQVU_cJdxxU', 'Chak De India', 0, 278],
['Mellisaye (Reprise) Manonmani', 'TAMIL', '2018', 'Yeshwanth | Nikhil Mathew', 'Jv477p7GpfM', '-', 6, 170],
['Daayre', 'HINDI', '2015', 'Pritam', '59esPpakrGs', 'Dilwale', 0, 288],
['Airplanes Pt II', 'ENGLISH', '2010', ' B.o.B', 'MK29MlRUt48', 'B.o.B Presents: The Adventures of Bobby Ray', 0, 313],
['Mustafa Mustafa', 'TELUGU', '1996', 'A R Rahman', 'iB8g5cB8am8', 'Prema Desam', 5, 366],
['Gravity Soundtrack ', 'ENGLISH', '2013 ', 'Steven Price', 'lSLVBkT5Stk', 'Gravity'],
['Emaindo Gaani', 'TELUGU', '2009', 'Achu & Dharan', 'E00t7gYiccE', 'Neenu Meeku Thelusa', 0, 250],
['Kalisundam Raa BGM', 'TELUGU', '2001', 'Mani Sharma', 't_ySjeWxj5U', ''],
['One Last Breath', 'ENGLISH', '2001', 'Creed', 'BzamjXouX5w', 'Weathered'],
['Manjal Veyil', 'TAMIL', '2006', 'Harris Jayaraj', 'y7ZgF0DsxDw', 'Vettaiyaadu Vilaiyaadu', 0, 348],
['Everybody Wants To Rule The World', 'ENGLISH', '1985', 'Tears for Fears', 'awoFZaSuko4', 'Songs from the Big Chair', 0, 249],
['Neduvali', 'HINDI', '2012', 'Amit Trivedi', '19CdwdItB3o', 'Ishaqzaade'],
['Requiem For A Dream', 'ENGLISH', '2000', 'Clint Mansell', 'yVIRcnlRKF8', 'Requiem For A Dream', 0, 383],
['Changure Changure', 'TELUGU', '1999', 'M. M. Keeravani', 'SWF98ohkddg', 'Sitha Rama Raju', 0, 312],
['Newyork Nagaram Acapella', 'TAMIL', '2020', 'A. R. Rahman', '99cC8x47jdE', 'Sillunu Oru Kaadhal'],
['Banjaara', 'HINDI', '2014', 'Mithoon', 'HDnLm1197oc', ' Ek Villain'],
['Love me like you do', 'ENGLISH', '2015', 'Ellie Goulding', '725WlG1idPc', 'DELIRIUM', 0, 247],
['Kadanna Preme', 'TELUGU', '2004', 'Yuvan Shankar Raja', '8P3NjaYXU1I', 'Manmadha', 0, 418],
['Sirikkadhey', 'TAMIL', '2016', 'Anirudh', 'iiVANEoXeLY', 'Remo', 0, 234],
['Sultans of Swing', 'HINDI', '2017', 'Pritam', 'YyF1FwiYcoQ', 'Jab Harry Met Sejal'],
['Baarish Atif Aslam', 'HINDI', '2008', 'Tanishk Bagchi', 'e-ZZRdTAcXg', 'Half Girlfriend'],
['Gharshana BGM 1', 'TELUGU', '2004', 'Harris Jayaraj', 'NJYMpvpMK9U', 'Gharshana'],
['Premante Suluvu', 'TELUGU', '2001', 'Mani Sharma', 'IBfoSYDwsyQ', 'Kushi', 0, 372],
['Tabaah Ho Gaye Male', 'HINDI', '2019', 'Pritam', 'S18EdCH5n7g', ' Kalank', 6, 44],
['Thalli Pogathey BGM - All Versions', 'TAMIL', '2020', 'A. R. Rahman', '180Vtd5pq1M', 'Achcham Yenbadhu Madamaiyada'],
['Dynamite', 'ENGLISH', '2005', 'Bon Jovi', 'JRB5w8zEd88', 'Oral Fixation Vol. 2'],
['Thalli Pogathey', 'TAMIL', '2016', 'A. R. Rahman', 'IarsrX60bZw', 'Achcham Yenbadhu Madamaiyada', 0, 263],
['Munbe vaa (BGM - All Versions) ', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 1', 'Sillunu Oru Kaadhal', 306, 332],
['Butta Bomma Instrumental Bgm', 'TELUGU', '2019', 'S. Thaman', 'Yrj2CQ71sCQ', 'Ala Vaikunthapurramloo'],
['Nijamena', 'TAMIL', '2005', 'Harris Jayaraj', 'vZgML3bfchk', 'Ghajini'],
['Behka', 'HINDI', '2019', 'Pritam', 'CuFtu3WPbj0', 'Kalank'],
['The Fighter', 'ENGLISH', '2011', 'Gym Class Heroes', 'bxV-OOIamyk', 'The Papercut Chronicles II'],
['Dream on Nobel Concert', 'ENGLISH', '2000', 'Hans Zimmer ', '4br5ARO3S20', 'Gladiator'],
['Tubthumping', 'TAMIL', '2008', 'A. R. Rahman', 'kBq3aj9lrI8', 'Sakkarakatti'],
['Toh Phir Aao', 'HINDI', '2007', 'Pritam', '-ML12N-EDs4', 'Awarapan'],
['Puvvullo Daagunna', 'TELUGU', '1998', 'AR RAHMAN', 'hfQwZRxNth0', 'Jeans', 0, 410],
['Now we are free cover', 'HINDI', '2009', 'Pritam', '3JiKUFWUJkk', 'Billu'],
['All Rise', 'ENGLISH', '2001', 'Blue', 'tvIn3xTNO9Y', 'All Rise'],
['Alejandro', 'HINDI', '2017', 'Pritam', 'ucniWROEZC4', 'Jab Harry Met Sejal'],
['Naan Un Azhaginile BGM', 'TAMIL', '2015', 'A R Rahman', 'qj_hvKMaL_E', '24'],
['Edhuta Nilichindhi', 'TELUGU', '2008', 'Kamalakar', 'rXumims1q98', 'Vaana', 16, 302],
['Kesariya', 'TAMIL', '2015', 'Harris Jayaraj', 'g6fnFALEseI', 'Anegan'],
['Adaraku', 'TELUGU', '2005', 'Mani Sharma', '1U6O5sSGnxo', 'Athadu', 0, 294],
['Yeah 3x', 'ENGLISH', '2012', 'Chris Brown', 'JKBlEIcqXRo', '-', 0, 239],
['DARLING BGM', 'TELUGU', '2010', 'GV Prakash', 'YpJ0nbcIc00', 'DARLING', 0],
['Alupannadi', 'TELUGU', '1993', 'Sri', 'sl9p70aBxac', 'Gaayam', 0, 346],
['Teenage Dream', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'Xpt9LkZo6ag', 'Pirates Of The Caribbean', 0, 322],
['Nee Dookudu', 'TELUGU', '2011', 'S. Thaman', 'c5Z4LLE31Pg', 'Dookudu', 0, 225],
['Columbus ', 'TAMIL', '1998', 'AR RAHMAN', 'qd26FnQz0yU', 'Jeans'],
['Endrendrum Punnagai', 'TAMIL', '2000', 'A. R. Rahman', 'TN3E6hT26_Q', 'Alaipayuthe', 0, 212],
['Minnale BGM 1', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 1', 'Minnale', 1168, 1215],
["Can't Help Falling In Love - Andrea Bocelli", 'ENGLISH', '1961', 'Elvis Presley', 'SPizIaBPhSg', 'Blue Hawaii', 46, 242],
['Layla', 'ENGLISH', '-', 'Billy Joel', 'TngViNw2pOo', '-'],
['Ye Mantramo', 'TELUGU', '2012', 'Radhan', 'eYPCqely7lg', 'Andala Rakshasi', 0, 163],
['Emi Sodara', 'TELUGU', '1998', 'Deva', 'Cq9Z4EqAx4Q', 'Tholiprema'],
['Marudhaani', 'TAMIL', '2008', 'A. R. Rahman', 'RKZNPx-K1AA', 'Sakkarakatti', 0, 384],
['En Jeevan Violin', 'TAMIL', '2016', 'G.V.Prakash Kumar', 'lBudipaPTfc', 'Theri'],
['Manmohini', 'HINDI', '2011', 'A R Rahman', 'FWCswftLz34', 'Yuvvraj'],
['One love', 'ENGLISH', '2002', 'Blue', 'Rc7cK0jjpL8', 'One love', 0, 205],
['Beauty Of Nature - Maahi Ve', 'HINDI', '2014', 'AR RAHMAN', 'jpTZA-wapaU', 'Highway'],
['Sankurathri Kodi', 'TELUGU', '2004', 'AR RAHMAN', 'LnSoGrf8xB8', 'Yuva', 1, 292],
['Beera', 'HINDI', '2010', 'A.R Rahman', '9oN4ePKC0MQ', 'Raavan'],
['7G Brundavan Colony BGM 4', 'TELUGU', '2007', 'Yuvan Shankar Raja', 'i7aSm_lG4yA 4', '7G Brundavan Colony', 143],
['Arjun Reddy bgm 2', 'TELUGU', '2017', 'Radhan', 'QEtkTPmkxV4', 'Arjun Reddy'],
['NEEVE', 'TELUGU', '2010', 'GV Prakash', 'Yp1S34JIugI', 'DARLING', 0, 278],
['Naari Naari', 'TAMIL', '2008', 'A. R. Rahman', 'NL0A5597OPI', 'Sakkarakatti'],
['Humsafar', 'TAMIL', '2005', 'Harris Jayaraj', 'vIUp4CzOrpQ', 'Ghajini'],
['Nijanga Nenena', 'TELUGU', '2008', 'Mickey J. Meyer', 'z2CDmSOubqc', 'Kothabangarulokam', 16, 335],
['Lean On', 'ENGLISH', '2015', 'MØ, Major Lazer, DJ Snake', 'YqeW9_5kURI', 'Peace Is The Mission'],
['Sawan Aaya Hai', 'HINDI', '2014', 'Tony Kakkar', 'B5_6jfGsyjA', ' Creature 3D', 0, 184],
['Telusa Telusa', 'TELUGU', '2016', 'S. Thaman', 'NUWmsA5z5yk', 'Sarrainodu', 0, 255],
['Delilah', 'HINDI', '2007', 'Vishal-Shekhar', 'SrIxz9wHUX8', 'Om Shanti Om'],
['Padi padi leche manasu BGM 1', 'TELUGU', '2018', 'Vishal Chandrasekhar', '95LQ35q9hGY 1', 'Padi Padi Leche Manasu', 122, 142],
['Masthana', 'TELUGU', '2005', 'Sandeep Chawtha', 'GBH17Zj3_CY', 'Super', 0, 304],
['AMANI PADAVE', 'TELUGU', '1989', 'IlayaRaaja', 't7IOxMBU4A4', 'Geethanjali'],
['Minnale BGM 5', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 5', 'Minnale', 1756, 1784],
['Sadeness', 'HINDI', '2017', 'Pritam', 'rCElGHuHDwY', 'Jab Harry Met Sejal'],
['Hymn For The Weekend', 'ENGLISH', '2015', 'Coldplay', 'YykjpeuMNEk', 'A Head Full of Dreams'],
['Nuvvunte', 'TELUGU', '2004', 'Devi Sri Prasad', 'Tn3UTYATiqw', 'Arya', 17, 325],
['Bheegi Si', 'HINDI', '2010', 'Pritam', 'ooH9UqewI7A', 'Raajneeti', 6, 274],
['Yun Hi Re', 'HINDI', '2013', 'Anirudh ', '0B_sHDt2k9I', 'David', 0, 280],
['Koi fariyaad Aakhon ke Sagar Cover', 'HINDI', '2004', 'Fuzön', '4aM3MzbkrTI', ' Saagar', 150],
['Mellaga Mellaga', 'TELUGU', '1995', 'Deva', '_C_3rZDdSAY', 'Asha Asha Asha'],
['Break Your Heart', 'ENGLISH', '2009', 'Taio Cruz', 'ddgcdagdbwI', 'Rokstarr ', 0, 200],
['Tera Ban Jaunga', 'HINDI', '2019', 'Akhil Sachdeva', '39BOqZO8xaM', 'Kabir Singh'],
['Mental Manadhilo Female', 'TAMIL', '2015', 'A. R. Rahman', 'Nx_OQUkjsHM', 'OK Kanmani', 0, 190],
['Django Unchained ● Main Theme', 'TAMIL', '2020', 'A. R. Rahman', 'v42Dx5eIoFQ', 'Sillunu Oru Kaadhal'],
['Munbe Vaa | Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'pNw9xbgYo7s', 'Songs from the Big Chair'],
['Sanda Kozhi', 'TAMIL', '2004', 'AR RAHMAN', 'sUU9BtSyLJ8', 'Yuva', 0, 292],
['New York City Surprise', 'ENGLISH', '2008', 'Hans Zimmer ', 'f5p_06s2hQg', 'Madagascar'],
['All Of The Lights', 'ENGLISH', '2010', 'Kanye West', 'HAfFfqiYLp0', 'My Beautiful Dark Twisted Fantasy', 6],
['Mukkala Mukabula ', 'TAMIL', '1994', 'A R Rahman', 'qfcx50xu9qU', 'Kaadhalan'],
['Raise Your Glass', 'ENGLISH', '2010', ' Pink', 'XjVNlG5cZyQ', 'Greatest Hits... So Far!!'],
['Dancing Queen', 'ENGLISH', '-', 'Billy Joel', '3ij_pUtJJrw', '-'],
['Khudaya Khair', 'HINDI', '2009', 'Pritam', 'jq69R33z4hU', 'Billu', 0, 278],
['Mari Antaga', 'TELUGU', '2013', 'Mickey J. Meyer', 'hCs8x7-67lk', 'Seethamma Vakitlo Sirimalle Chettu', 16, 240],
['Everybody talks', 'ENGLISH', '2012', 'Neon Trees', 'X5G9tIe84lE', 'Picture Show (Deluxe Edition)', 26, 220],
['O Re Piya-Rolling in the deep Cover', 'HINDI', '2007', 'Salim–Sulaiman', 't-y0EnReO74', 'Aaja Nachle'],
['Ghar More Pardesiya', 'HINDI', '2019', 'Pritam', 'pp8vAFFc5E0', 'Kalank', 0, 315],
['Kadhal Sadugudu', 'TAMIL', '2000', 'A. R. Rahman', 'GjZKBJ5Fprw', 'Alaipayuthe ', 0, 215],
['Padayappa Theme 4', 'TAMIL', '1999', 'A R Rahman', 'eguXwwDSQNM 4', 'Padayappa', 964, 995],
['Padayappa sad bgm', 'TAMIL', '2005', 'Harris Jayaraj', 'c5bXvPkBB44', 'Ghajini'],
['The Chainsmokers - Roses', 'ENGLISH', '2015', 'The Chainsmokers', 'FyASdjZE0R0', 'Bouquet', 0, 225],
['Whatever it takes', 'ENGLISH', '2017', 'Imagine Dragons', 'M66U_DuMCS8', 'Whatever It Takes (Miss Congeniality Remix)', 0, 198],
['King of Pride Rock', 'ENGLISH', '-', 'Hans Zimmer ', '6HQgXzyw-Fo', 'Lion King'],
['Pacha Bottasi', 'TELUGU', '1996', 'A.R Rahman', 'ZLLPLH_wKbU', 'Bharateeyudu'],
['Enno ratrulostayi gani cover', 'ENGLISH', '1985', 'Tears for Fears', 'KpcfHX0zAsc', 'Songs from the Big Chair'],
['Thakadimithom', 'TELUGU', '2004', 'Devi Sri Prasad', 'sSwvEC01-W0', 'Arya', 0, 332],
['Tujh Mein Rab Dikhta Hai Flute Bit', 'HINDI', '2008', 'Salim-Sulaiman', 'm7oBC7b_7TI', 'Rab Ne Bana Di Jodi', 0],
['Oh Manasa', 'TELUGU', '1997', 'Ramani Bharadwaj', '-m0ow-65zH0', 'Oka Chinna Maata', 0, 281],
['Somebody That I Used To Know', 'ENGLISH', '2012', 'Gotye', 'Q7DtJJSVPsk', 'Making Mirrors', 0, 242],
['DIL KYUN YEH MERA', 'HINDI', '2010', 'Rajesh Roshan', 'GlMExeJMAGs', 'Kites', 0, 330],
['7G Brundavan Colony BGM 2', 'TELUGU', '2007', 'Yuvan Shankar Raja', 'i7aSm_lG4yA 2', '7G Brundavan Colony', 43, 86],
['Remo - BGM 2', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 2', 'Remo', 112, 182],
['Iktara', 'HINDI', '2009', 'Amit Trivedi', 'pdB7v1z8PJk', 'Wake Up Sid', 0, 250],
['LABON KO', 'HINDI', '2007', 'Pritam', '12pMB_mCBOo', 'Bhool Bhulaiyaa', 0, 338],
['Yellae Lama', 'TELUGU', '2011', 'Harris Jayaraj', 'XxBe09fqqNQ', '7th Sense', 0, 316],
['Dosth Bada Dosth', 'TELUGU', '2008', 'Yuvan Shankar Raja', '1GG2rVRsg0U', 'Saroja', 0, 276],
['Dangal - Title Track', 'HINDI', '2016', 'Pritam', 'y60avV4uiPM', 'Dangal', 0, 295],
["C'est La Vie", 'FRENCH-ARABIC', '2013', 'Cheb Khaled', 'defnPu0Jz3Y', 'Cest La Vie (Remixes)'],
['Mariyan Love Bgm', 'TAMIL', '2013', 'AR Rahman', 'ANrG-Oe5IxM', 'Maryan'],
['See You Again', 'ENGLISH', '2010', 'Charlie Puth', 'RgKAFK5djSk', 'See You Again', 0, 225],
['Bagundey', 'TELUGU', '2007', 'Devi Sri Prasad', 'nm2quvqZ5OE', 'Shankardada Zindabad'],
['Chevaliers De Sangreal', 'ENGLISH', '2003  ', 'Hans Zimmer', 'CpHkMZxNLV0', 'Da Vinci Code'],
['Dream On Orchestra', 'ENGLISH', '2012', 'Imagine Dragons', '5UJ8SriS6rg', 'Continued Silence EP'],
['Zara si dil me de jagah tu', 'HINDI', '2008', 'Pritam', 'oiD-rVWEXjM', 'Jannat', 0, 300],
['Itni Si Baat Hain', 'HINDI', '2019', 'Pritam', 'jhPtPGKdQ-w', ' Azhar'],
['Aahista Aahista', 'HINDI', '2008', 'Vishal-Shekhar', '-m9IGI5EgyM', 'Bachna Ae Haseeno', 10],
['Toh Phir Aao Remix', 'HINDI', '2007', 'Pritam', 'Ahn5Uv9lppA', 'Awarapan'],
['Guzarish', 'HINDI', '2011', ' Krsna Solo', 'cK9h5PVzRpk', 'Tanu Weds Manu'],
['Chukkalo Chandrude', 'TELUGU', '2002', 'Mani Sharma', 'lI7Y8bYzpn4', 'Takkari Donga', 0, 273],
['Where Is My Mind?', 'ENGLISH', '1988', ' Pixies', 'N3oCS85HvpY', 'Surfer Rosa'],
['Manasa Ottu', 'TELUGU', '2003', 'M. M. Keeravani', 'UKSjkZJsMQY', 'Pilisthe Palukutha', 0, 292],
['Tum Mile Rock', 'HINDI', '2009', 'Pritam', 'TvfRfddB5rc', ' Tum Mile'],
['Kinnerasani', 'TELUGU', '1984', 'IlayaRaaja', 'w0mSgwvXkxE', 'Sitara'],
['Cheerleader', 'ENGLISH', '2014', 'Omi', 'jGflUbPQfW8', 'Me 4 U', 8, 187],
['Do You Know', 'HINDI', '2007', ' Enrique Iglesias', 'ibSKivYOPpI', 'Insomniac', 0, 216],
['Lingesans Cloud Nine', 'TAMIL', '2017', 'AR Rahman', 'yCBMo4Gse0A', 'I'],
['Masthura', 'TELUGU', '2003', 'A R Rahman', 'OwMGQ7j1ozQ', 'Nee Manasu Naaku Telusu', 6, 308],
['Sarrainodu', 'TELUGU', '2016', 'S. Thaman', 'AwdZGsBUq3c', 'Sarrainodu'],
['Hawayein', 'HINDI', '2017', 'Pritam', 'cYOB941gyXI', 'Jab Harry Met Sejal', 0, 287],
['Hallelujah', 'ENGLISH', '-', 'Billy Joel', 'y8AWFf7EAc4', '-'],
['Kanave Kanave', 'TAMIL', '2013', 'Anirudh ', 'qD53-RZpTOc', 'David'],
['SAJDE', 'HINDI', '2010', 'Pritam', 'zfABYXP_NSA', 'KHATTA MEETHA', 0, 602],
['Chain', 'JAPANESE', '2006', 'BACK-ON', 'klFVxGAacz0', 'Chain'],
['Emai Poyave', 'TELUGU', '2018', 'Vishal Chandrasekhar', 'QfF5ceRzrqI', 'Padi Padi Leche Manasu', 0, 151],
['Moonu Bike Ride BGM ', 'TAMIL', '2011', 'Anirudh', 'Wp-CqqC5-Tc', 'Moonu'],
['Mental Manadhil Female', 'TAMIL', '2015', 'A. R. Rahman', 'jEuojck5M7U', 'OK Bangaram', 0, 194],
['Holiday', 'ENGLISH', '2004', 'Green Day', 'sO-S32xnXA0', 'American Idiot'],
['My Heart Will Go On  Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'pht2DKDW4ig', 'Songs from the Big Chair'],
['Achillies last stand', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'P-Rf1I9htJk', 'Pirates Of The Caribbean'],
['Behka BGM', 'HINDI', '2012', 'Amit Trivedi', 'qt29hExhwj0', 'Ishaqzaade'],
['RHTDM - Special Love flute', 'HINDI', '2001', 'Harris Jayaraj ', '5hXvn4AlbEc', 'Rehna Hai Tere Dil Mein'],
['Roses', 'ENGLISH', '2011', 'One Direction', 'ISMPuZDDdkI', 'Up All Night'],
['Naa Chupe Ninu', 'TELUGU', '2001', 'Koti', 'ESHIva7b-OQ', 'Nuvvu Naaku Nachchav', 18, 266],
['Ghajini BGM 4', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 45', 'Ghajini', 910, 958],
['O Prema', 'TELUGU', '2002', 'Harris Jayaraj', 'Q1HHRvWksE8', 'Vaasu', 0, 346],
['Smells Like Teen Spirit', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'JyGNABrosb8', 'Pirates Of The Caribbean'],
['My Dil Goes Mmmm', 'HINDI', '2005', 'Vishal–Shekhar', 'Wk4z3rc84bc', 'Salaam Namaste', 0, 444],
['Seven nation army', 'ENGLISH', '2003', 'The White Stripes', 's2i82up9E1w', 'Elephant'],
['Channa Mereya (Instrumental)', 'HINDI', '2016', 'Pritam', 'jqtGUzGY3cs', 'Ae Dil Hai Mushkil', 0, 284],
['Kannulu Neevi', 'TELUGU', '2001', 'Harris Jayaraj', 'VJ8yLmkRzd0', 'Cheli', 0, 110],
['Kottaga BGM 1', 'TELUGU', '1988', 'IlayaRaaja', 's9h0kmdSXx8 1', 'Swarna Kamalam', 0, 28],
['Moonu BGMs Phone Num', 'TAMIL', '2011', 'Anirudh', '9vI2aWbAupU', 'Moonu', 290, 318],
['Alladhe Siragiye', 'TAMIL', '2016', 'Anirudh', 'qLghPlhluGI', 'Rum', 0, 220],
['Aei Vennella Sona', 'TELUGU', '2001', 'Harris Jayaraj', 'UCDm1iR9y7Y', 'Cheli', 0, 342],
['Tak Bak', 'TAMIL', '2015', 'Anirudh ', 'bSvct1cxLys', 'Thanga Magan', 0, 152],
['Sanam Re', 'HINDI', '2012', 'Ajay-Atul', 'WH3AE91ux3M', 'Agneepath'],
["All along the Watchtower / Don't fear the Reaper", 'ENGLISH', '2015', 'Jimi Hendrix', 'U7APCoFNtRs', 'The Monkey Biscuits', 0, 224],
['Venmathi BGM', 'TAMIL', '2001', 'Harris Jayaraj', 'BKeV8kIdJS4', 'Minnale'],
['Madhura Madhuratara', 'TELUGU', '2004', 'Mani Sharma', '4PZ4LkLVQx0', 'Arjun', 0, 330],
['Iru Vizhili & Nenjukkul Peidhidum', 'TAMIL', '2014', 'Devan Ekambaram', 'm_tmtqfHj2E', "Sammy's Live Lounge ", 23, 486],
['Good Feeling', 'ENGLISH', '2011', 'Flo Rida', '3OnnDqH6Wj8', 'Absolute Hits 2011', 0, 245],
['Tere Sang', 'HINDI', '2007', 'Vishal-Shekhar', 'nzlt_Sda0cM', 'Om Shanti Om', 0, 302],
['Unakkenna Venum Sollu', 'TAMIL', '2015', 'Harris Jayaraj', '2OFuWfpBS-s', 'Yennai Arindhaal', 0, 304],
['Vastha Nee Venuka', 'TELUGU', '2006', 'A. R. Rahman', 'D_4nLWISLu4', 'Naani', 5, 336],
['Manmohini-Aditya Rao', 'HINDI', '2011', 'A R Rahman', '6RBdF1ZZZ_I', 'Yuvvraj', 0, 184],
['Then Then Then', 'TAMIL', '2008', 'Vidyasagar', 'iIQTmOq2Jgc', 'Kuruvi', 0, 230],
['𝐂𝐚𝐧𝐨𝐧 𝐢𝐧 𝐃 𝐏𝐞𝐫-𝐎𝐥𝐨𝐯', 'ENGLISH', '198                              8', ' Pixies', 'jXC9tuumjiA', 'Surfer Rosa'],
['Another Brick In The Wall', 'ENGLISH', '1979', 'Pink Floyd', 'NoK1JmTUzCI', 'The Wall', 0, 556],
['Numb Orchestral', 'ENGLISH', '-', 'Billy Joel', '8PIR1kvaDkQ', '-'],
['In the Hall of the Mountain King', 'ENGLISH', '2008', 'Trent Reznor, Atticus Ross', 'cD8EPdn5Ctg', 'The Social Network'],
['Manmaduda ne', 'TELUGU', '2004', 'Yuvan Shankar Raja', 'kwaPZrvwjs4', 'Manmadha', 5, 272],
['Suno Aisha', 'HINDI', '2010', 'Amit Trivedi', '49WSoibFQDo', 'Aisha', 0, 262],
['Do you know', 'ENGLISH', '2007', 'Enrique Iglesias', 'dZ7bW8JVlco', 'Insomniac', 0, 217],
['Aquaman 2 ', 'ENGLISH', '2018', 'Skylar Grey', 'VJAFioSyI20', 'Aquaman', 230, 254],
['Evare', 'TELUGU', '2016', 'Rajesh Murugesan', 'KG5UfTiBrAo', 'Premam', 4, 310],
['Tu Jaane Na', 'HINDI', '2009', 'Pritam', 'P8PWN1OmZOA', 'Ajab Prem Ki Ghazab Kahani', 0, 635],
['Memories guitar cover', 'ENGLISH', '2019', 'Maroon 5', 'isJ9Ez_j480', 'Memories'],
['Toxicity', 'ENGLISH', '2001', 'System Of A Down', 'mUEsqQpact0', 'Toxicity'],
['Vinnaithandi Varuvaya BGM 1', 'TAMIL', '2010', 'A R Rahman', 'hPgnVhn5P7E', 'Vinnaithaandi Varuvaayaa'],
["Don't You Worry Child", 'ENGLISH', '2012', 'Swedish House Mafia', '3mWbRB3Y4R8', 'Until Now', 0, 208],
['Icky thump', 'ENGLISH', '2007', 'The White Stripes', 'm1KyC_LzvzQ', 'Icky Thump'],
['Aalaporaan Thamizhan', 'TAMIL', '2017', 'A.R Rahman', '3XmrZaVVUpc', 'Mersal', 0, 341],
['En Jeevan', 'TAMIL', '2016', 'G.V.Prakash Kumar', 'H3GhtM8V-dU', 'Theri', 0, 315],
['Kanna Nidurinchara', 'TELUGU', '1996', 'A.R Rahman', 'O1UC2Oszg38', 'Bharateeyudu'],
['Now We Are Free', 'ENGLISH', '2000', 'Hans Zimmer ', 'PvrDI6U0mE4', 'Gladiator'],
['Rockstar | End Credits', 'HINDI', '2011', 'A R Rahman', 'Fp6jERibpxY', 'Rockstar'],
['Pirates of the Caribbean Piano Solo', 'ENGLISH', '2013', 'Hans Zimmer Jarrod Radnich ', 'n4JD-3-UAzM', 'Pirates of the Caribbean', 0, 124],
['Lambada', 'TAMIL', '2008', 'A. R. Rahman', 'tVrvTPD6OMQ', 'Sakkarakatti'],
['Stairway To Heaven - Live', ' ENGLISH', '1971', ' Led Zeppelin', 'xbhCPt6PZIU', ' The Song Remains the.. '],
['All Along The Watchtower', 'ENGLISH', '1968', 'The Jimi Hendrix Experience, Curtis Knight & Jimi Hendrix', 'TLV4_xaYynY', 'Electric Ladyland', 0, 238],
['Justice League Theme', 'ENGLISH', '2002', 'Lolita Ritmanis', 'tFVkIqlKNfE', 'Justice League Theme'],
['Newyork Nagaram', 'TAMIL', '2006', 'A. R. Rahman', '5WejuSV-sKU', 'Sillunu Oru Kaadhal', 0, 372],
['Little Talks', 'ENGLISH', '2011', 'Of Monsters and Men', 'ghb6eDopW8I', 'My Head Is an Animal'],
['Kaise Hua ', 'HINDI', '2019', 'Mithoon', 'WKv07mnKVEE', 'Kabir Singh'],
['Time', 'ENGLISH', '1973', 'Pink Floyd', '-EzURpTF5c8', 'The Dark Side of the Moon', 0, 403],
['Yeh Ishq Hai', 'HINDI', '2007', 'Pritam', 'b_sCZbYyuO4', ' Jab We Met', 0, 275],
['Pirates Of The Caribbean Medley (Live)', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'NZWkUajiE5Q', 'Pirates Of The Caribbean'],
['Aakhon ke Sagar', 'HINDI', '2004', 'Fuzön', 'dxekUFhmFu4', ' Saagar', 0, 314],
['Sarele Vooruko', 'TELUGU', '1996', 'Sri', 'AHqGPlZ6wg0', 'Little Soldiers', 6, 247],
['The Dark Knight Main Theme', 'ENGLISH', '2008', 'Hans Zimmer ', 'iGx5a1ifSDs', 'The Dark Knight', 0, 456],
['Remo - BGM 1', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 1', 'Remo', 0, 98],
['Kushi BGM 2', 'TELUGU', '2001', 'Mani Sharma', 'xk8vIHXiSF4', 'Kushi', 217, 273],
['Ghajini BGM', 'TAMIL', '2005', 'Harris Jayaraj', 'MsmAkJ2tI8g', 'Ghajini'],
['Jeans BGM 3', 'TAMIL', '1998', 'AR RAHMAN', 'K_SMe3U9l34 3', 'Jeans', 623, 672],
['Kathi BGM', 'TAMIL', '2017', 'Anirudh', 'hqK7Genl8cE', 'Kathi'],
['Im Good', 'TAMIL', '2005', 'Harris Jayaraj', 'QbdpqHAVlrI', 'Ghajini'],
['Nammave Ammai ', 'TELUGU', '2002', 'Harris Jayaraj', '0X76GY-iKFU', 'Vaasu', 5, 280],
['Padayappa Theme 5', 'TAMIL', '1999', 'A R Rahman', 'eguXwwDSQNM 5', 'Padayappa', 1398, 1446],
['Remo - BGM 3', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 3', 'Remo', 184, 264],
['AADAT Remix', 'HINDI', '2007', 'JAL', 'gu3UMY2EzXE', 'Kalyug'],
['Wild Ones', 'ENGLISH', '2012', 'Flo Rida-Sia ', 'bpOR_HuHRNs', ' Wild Ones', 0, 231],
['The Maari Swag', 'TAMIL', '2015', 'Anirudh', 'AeY7_k77zKg', 'Maari'],
['Translucid', 'HINDI', '2017', 'Pritam', 'CTRL6AtIiog', 'Jab Harry Met Sejal'],
['Chittiyaan Kalaiyaan', 'PUNJABI', '1996', 'A.R Rahman', 'ypXROLupeVg', 'Bharateeyudu'],
["Don't Fear The Reaper", 'ENGLISH', '1976', 'Blue Öyster Cult', 'RjMEzBMiTJ4', 'Agents of Fortune', 0, 383],
['Piano Man', 'ENGLISH', '-', 'Billy Joel', 'QwVjTlTdIDQ', '-'],
['Beautiful ', 'ENGLISH', '2008', 'Akon', '1_2uhaug3BQ', ' Beautiful (Reggae Remix)'],
['Munbe vaa BGM - 1', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 2', 'Sillunu Oru Kaadhal', 258, 305],
['Matargasthi', 'HINDI', '2015', 'A R Rahman', 'TpMSsbzvRe4', 'Tamasha'],
['Sadda Haq', 'HINDI', '2011', 'A R Rahman', 'QTQVG5rSMkQ', 'Rockstar', 0, 358],
['Jolly O Gymkhana', 'TAMIL', '1999', 'A R Rahman', 'uVbSqPy18hU', 'Padayappa'],
['Chitti Aayirey', 'TELUGU', '2008', 'Sandeep Chawtha', 'H_HjIRFBelQ', 'Bujjigadu', 5, 286],
['Roobaroo', 'HINDI', '2006', 'A. R. Rahman', 'BrfRB6aTZlM', 'Rang De Basanti', 0, 280],
['Forever young', 'ENGLISH', '2009', 'JAY-Z · Mr Hudson', 'AUFQFUR7Zfo', 'The Blueprint 3', 0, 248],
['Maro Maro', 'TAMIL', '2003', 'A R Rahman', 'Oh73NiW9nkQ', 'Boys', 0, 337],
['Let her go', 'ENGLISH', '2012', 'Passenger', 'dT4A3rttrs8', 'All the Little Lights', 0, 249],
['Bhaag DK Bose', 'HINDI', '2011', 'Ram Sampath', '3ICVjtAtBoE', 'Delhi Belly'],
['Hamsaro', 'TELUGU', '2017', 'AR Rahman', 'Hz7wk-5KmAc', 'Cheliyaa'],
['Mazhai Kuruvi', 'TAMIL', '2019', 'A.R. Rahman', 'bt4-FwVe1Fk', 'Chekka Chivantha Vaanam', 0, 345],
['Maro Masthi Maro', 'HINDI', '2007', 'A. R. Rahman', 'H82jrbj1rS0', 'Guru'],
['Mitwa', 'HINDI', '2006', 'Shankar-Ehsaan-Loy', 'AsieVqOTRs0', 'Kabhi Alvida Naa Kehna', 0, 376],
['Humko Pyaar Hua', 'HINDI', '2011', 'Pritam', 'EB25MOJIHRQ', 'Ready', 0, 322],
['Pillaa Raa', 'TELUGU', '2018', 'Chaitan Bharadwaj', 'ivPJ7WB2u5M', 'RX 100'],
['Maahi Ve', 'HINDI', '2014', 'A.R Rahman', 'S1aQhVUy_9g', ' Highway', 0, 237],
['Yaaraiyum Ivlo Azhaga', 'TELUGU', '1996', 'A R Rahman', 'O2QDdiZTnnQ', 'Prema Desam'],
['Replay (Prequel)', 'ENGLISH', '2009', 'Iyaz', 'WXxV9g7lsFE', 'Replay', 9, 190],
['o sayonara', 'TELUGU', '2014', 'Devi Sri Prasad', 'W9hPmEM1SkE', 'nenokkadine'],
['Love the way you lie', 'ENGLISH', '2010', 'Eminem', 'uelHwf8o7_U', 'Recovery', 0, 264],
['Raalipoye Poova', 'TELUGU', '1993', 'M.M.Keeravani', 'HY8pUrldSY0', 'Mathrudevobhava', 0, 248],
['One Time', 'ENGLISH', '2011', 'One Direction', 'CHVhwcOg6y8', 'Up All Night'],
['Yeh Aaina', 'HINDI', '2019', 'Amaal Mallik', 'UcOx6Bm3_4k', 'Kabir Singh'],
['Maula Mere Maula', 'HINDI', '2007', 'Mithoon', 'mU2e2ct9Vhg', 'Anwar', 0, 352],
['Kaalaiyil dhinamum BGM', 'TAMIL', '2004', 'A. R. Rahman', 'FWT1sFzzBDc', 'New'],
['Piano Man Live', 'ENGLISH', '-', 'Billy Joel', '7pF04BaF9SQ', '-', 0, 372],
['Tukur Tukur', 'HINDI', '2015', 'Pritam', 'hp0g6p2jjvo', 'Dilwale', 0],
['Munbe vaa BGM ', 'TAMIL', '2020', 'A. R. Rahman', 'Py-XY9e24Nw', 'Sillunu Oru Kaadhal', 0, 102],
['Classic', 'ENGLISH', '2013', 'MKTO', 'AWgDP8PQWRY', 'Classic'],
['Nuvole Bianche', 'INSTRUMENTAL', '2018', 'Ludovico Einaudi', 'qFfeMeXakhY', '-'],
['Gore Gore', 'TELUGU', '2007', 'Devi Sri Prasad', 'aIqsmk1-RmA', 'Shankardada Zindabad'],
['Memories Ft Hawayein | Flute Version', 'ENGLISH', '1985', 'Tears for Fears', 'TUhN4N6Up1w', '-'],
['Sirivennela', 'TELUGU', '1989', 'IlayaRaaja', 'OGplMBWst6Y', 'Geethanjali', 0, 320],
['La Caution - Thé à la Menthe', 'TELUGU', '1996', 'A R Rahman', 'k4Xx0k_TVY0', 'Prema Desam'],
['Tujhe Kitna Chahne cover', 'HINDI', '2019', 'Mithoon', '8kp4M4zRe6c', 'Kabir Singh'],
['Munbe Vaa', 'TAMIL', '2006', 'A. R. Rahman', 'aAn3tUbBg3Q', 'Sillunu Oru Kaadhal', 0, 350],
['Asha Pasham', 'HINDI', '2016', 'Pritam', 'dV83EugXUMo', 'Ae Dil Hai Mushkil'],
['Malare', 'MALAYALAM', '2015', 'Rajesh Murugesan', '0G2VxhV_gXM', 'Premam', 0, 290],
['Treat You Better', 'ENGLISH', '2016', 'Shawn Mendes', 'EvDz91ZmOD0', 'Illuminate', 0, 180],
['Sehri', 'TELUGU', '2009', 'Yuvan Shankar Raja', 'PyiQNdzKYCM', 'Oy'],
['Surya seeing Asin at first time BGM', 'TAMIL', '2005', 'Harris Jayaraj', 'T1MsC_yjCO4', 'Ghajini', 0],
['Surviva', 'TAMIL', '2017', 'Anirudh ', 'YdX9sctT8fg', 'Vivegam', 0, 218],
['Tere Bin Kaise', 'HINDI', '2006', 'Mithoon', 'yIrU21hoHys', 'Bas Ek Pal', 12, 283],
['Fix You', 'ENGLISH', '2005', 'Coldplay', 'SYmJ_YcC_Fg', 'X&Y', 0, 270],
['Barbie Girl', 'ENGLISH', '1997', 'Aqua', 'yjrrG_JWD_Q', 'Aquarium', 0, 192],
['Music Bit - Vasu', 'TELUGU', '2002', 'Harris Jayaraj', 'XoqXEdsB74c', 'Vaasu', 0, 94],
['Nothing Else Matters', 'ENGLISH', '1992', 'Metallica', 'tAGnKpE4NCI', 'Metallica'],
['Skyfall', 'ENGLISH', '2012', 'Adele', 'DeumyOzKqgI', 'Skyfall', 0, 282],
['Yemito', 'TELUGU', '2012', 'Radhan', 'Gi7qgVoiYt4', 'Andala Rakshasi', 0, 264],
['Kaali Theme', 'TAMIL', '2019', 'Anirudh', 'lAUUTzmQy0Y', 'Petta', 0, 63],
['Gone, Gone, Gone', 'ENGLISH', '2012', 'Phillip Phillips', 'oozQ4yV__Vw', 'The World from the Side of the Moon', 5, 213],
['Interval Bang Music Bit', 'TELUGU', '2002', 'Harris Jayaraj', 'zhfvbBgv6z0', 'Vaasu'],
['The Ketchup Song', 'SPANISH', '2002', 'Las Ketchup', 'AMT698ArSfQ', 'Hijas del Tomate'],
['Remo - BGM 9', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 9', 'Remo', 774, 838],
['Arere Arere', 'TELUGU', '2007', 'Mickey J. Meyer', 'ta8SNlHsEdk', 'Happy Days', 0, 290],
["It's Rocking ", 'HINDI', '2007', 'Pritam', 'TF5PU-0zyIY', 'Kya Love Story Hai', 11, 307],
['PRIYATAMA BGM', 'TELUGU', '2017', 'Radhan', 'HXR-QVynsUQ', 'Arjun Reddy', 0, 71],
['Gerua', 'HINDI', '2015', 'Pritam', 'pRCQEI4GGr8', 'Dilwale'],
['Afterglow', 'ENGLISH', '2005', 'INXS', 'OnTelUJrDhc', 'Switch', 0, 244],
['Chiluka Kshemama', 'TELUGU', '1991', 'Bappi Lahiri', 'WeTFUAe7Rvs', 'Rowdy Alludu', 0, 332],
['Mudhal Mudhalaaga', 'TELUGU', '2007', 'Harris Jayaraj', 'qnas0ftZCyE', 'Unnale Unnale', 0, 280],
['Chain', 'JAPANESE', '2006', 'BACK-ON', '0rb0aQfYMWU', 'Chain', 378, 518],
['The Monster', 'ENGLISH', '2010', 'Eminem', 'EHkozMIXZ8w', 'The Marshall Mathers LP 2', 60, 312],
['Tattad', 'HINDI', '2015', 'A R Rahman', 'IqpPJ6B2sLE', 'Tamasha'],
['Galliyan', 'HINDI', '2014', 'Ankit Tiwari', 'PL8X5gq9ZlQ', ' Ek Villain'],
['Thunderstruck', ' ENGLISH', '1990', 'AC/DC', 'n_GFN3a0yj0', 'Live at Donington'],
['Treat You Better Emma Heesters & Markus Cole Cover', 'ENGLISH', '2016', 'Shawn Mendes', 'MHqa19bplis', 'Illuminate', 0, 198],
['Kaaka Kaaka BGM 2', 'TAMIL', '2003', 'Harris Jayaraj', '6wb6Nyw2E5U', 'Kaakha Kaakha'],
['Maahi Ve cover', 'HINDI', '2014', 'A.R Rahman', 'wgCvhDSX2gM', ' Highway'],
['Kambathu Ponnu', 'Tamil', '2018', 'Yuvan Shankar Raja', 'KPDhwjmb11o', 'Sandakozhi 2'],
['Marana Mass', 'TAMIL', '2019', 'Anirudh', 'HHk-Fk7wTfo', 'Petta'],
['I Dont Wanna Live Forever', 'ENGLISH', '2012', 'Rolling Stones', 'z6x0nGslk6w', 'Hall of Fame'],
['Try', 'ENGLISH', '2012', ' Pink', 'pPtlSF4TlJE', 'The Truth About Love', 0, 244],
['Memories (Cover)', 'ENGLISH', '2019', 'Maroon 5', 'XB6yjGVuzVo', 'Memories', 0, 219],
['Viva La Vida', 'ENGLISH', '2008', 'Coldplay', 'dvgZkm1xWPE', 'Viva La Vida or Death and All His Friends', 0, 236],
['Mike Dawes: All Along the Watchtower', 'ENGLISH', '1985', 'Tears for Fears', 'hAZVtPJg6cM', '-'],
['Rude', 'ENGLISH', '2013', 'MAGIC!', 'aRtJqhe6YaY', 'Rude'],
['Nammavemo', 'TELUGU', '2008', 'Mani Sharma', 'H47mzxzTDuI', 'Parugu', 0, 286],
['Aye Khuda', 'HINDI', '2010 ', 'Hanif Sheikh', 'T-g39o0rDos', 'Paathshaala'],
['Tum Mile', 'HINDI', '2009', 'Pritam', 'odVptmgIcD0', ' Tum Mile', 0, 342],
['TiK ToK', ' ENGLISH', '2009', ' Kesha', 'iP6XpLQM2Cs', 'TiK ToK'],
['Munbe vaa BGM - 3', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 4', 'Sillunu Oru Kaadhal', 0, 78],
['Doorie', 'HINDI', '2006', 'Atif Aslam', 'Jv2Z9KJgYjU', 'Doorie', 6, 292],
['Let It Be', 'ENGLISH', '2009', 'The Beatles', 'QDYfEBY9NM4', 'Let It Be (Remastered 2009)', 0, 240],
['Aye Amaayika', 'TELUGU', '2015', 'A. R. Rahman', 'gB0-MOcIiBU', 'OK Bangaram', 0, 389],
['Eastside', 'ENGLISH', '2018', 'Benny Blanco', 'KFof8aaUvGY', 'Eastside', 0, 170],
['Sportive Boys', 'TELUGU', '2002', 'Harris Jayaraj', 'C31FdwTtYfc', 'Vaasu', 4, 312],
['Dil Kya Kare', 'HINDI', '2007', 'Shankar-Ehsaan-Loy', 'gZjnUcJy-Rw', 'SALAAM-E-ISHQ', 0, 324],
['Andha Kanna Paathaakaa', 'TAMIL', '2020', 'Anirudh', '3hVc3M1IEe0', 'Master'],
['Columbus Telugu', 'TELUGU', '1998', 'AR RAHMAN', '7hPukKvvXxU', 'Jeans', 0, 299],
['Khamoshiyan', 'HINDI', '2015', 'Mithoon', 'Mv3SZDP7QUo', ' Khamoshiyan'],
['Afghan Jalebi', 'HINDI', '2015', 'Pritam', 'V_92Ry6aKe0', 'Phantom', 0, 223],
['Minnale BGM 2', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 2', 'Minnale', 1423, 1480],
['Ennodu ( Original & Reprise) ', 'TAMIL', '2014', 'AR Rahman', 'M-kH96zWPxM', 'Hummingjays.com'],
['Channa Mereya Unplugged', 'HINDI', '2016', 'Pritam', 'PYzFp5o4lhE', 'Ae Dil Hai Mushkil', 0, 164],
['Samjhawan | Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'Fmo_VpBKHYc', '-'],
['Canon in D Major', 'TAMIL', '2008', 'A. R. Rahman', 'NlprozGcs80', 'Sakkarakatti'],
['Mona Gasolina', 'TAMIL', '2014', 'AR RAHMAN', 'hSXneTM-R5M', 'Lingaa', 0, 250],
['Something Just Like This', 'ENGLISH', '2017', 'Coldplay-The Chainsmokers', 'FM7MFYoylVs', 'Something Just Like This'],
['Summer', ' ENGLISH', '2014', 'Calvin Harris', 'ebXbLfLACGM', 'Summer', 14, 226],
['Chop Suey Orchestral', 'ENGLISH', '2001', 'System Of A Down', '3wDjOkqIr24', 'Toxicity'],
['Get Ready', 'TELUGU', '2008', 'Devi Sri Prasad', 'Gb2HdYEaFGI', 'Ready'],
['People Get Up And Drive Your Funky Soul', 'ENGLISH', '2012', 'Leonard Cohen', '0oxt-WtC6Oc', ''],
['Roja Kadale', 'TAMIL', '2015', 'Harris Jayaraj', '9hVjc3sUSjA', 'Anegan'],
['Enno Ratrulostayi', 'TELUGU', '1992', 'IlayaRaaja', 'Xc5K9EDnHKQ', 'Dharmakshetram', 0, 295],
['Munbe vaa BGM - 6', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 7', 'Sillunu Oru Kaadhal', 220, 236],
['DIL DOOBA', 'HINDI', '2004', 'Ram Sampath', 'eG-cS13QWmM', 'Khakee', 0, 226],
['Na Hrudayam', 'TAMIL', '2013', 'AR Rahman', 'MsymghOuogg', '-'],
['Blank Space ', 'ENGLISH', '2014', 'Taylor Swift', 'WZ605-Hflqg', ' 1989', 3],
['Ye Tune Kya Kiya cover', 'HINDI', '2013', 'Pritam', 'RvJ3P41JsaY', 'Once Upon A Time In Mumbai Dobaara!'],
['Manase Eduru', 'TELUGU', '1998', 'Ramana Gogula', 'L4oL_VXEkN0', 'Premante Idera', 6, 292],
['Die Young', ' ENGLISH', '2012', ' Kesha', 'NOubzHCUt48', 'Die Young'],
['Manasa', 'TELUGU', '2007', 'Harris Jayaraj', 'KPMmu9y_88I', 'Munna', 4, 346],
['Eastside cover', 'ENGLISH', '2018', 'Benny Blanco', 'rZZlWR-AHUY', 'Eastside'],
['AADAT INSTRUMENTAL', 'HINDI', '2007', 'BHANWARAY feat. Goher Mumtaz', 'Q3TI27IN7X0', 'AADAT', 7, 552],
['You Know My Name', 'ENGLISH', '2007', 'Chris Cornell', 'HlQsC-o2y5c', 'Casino Royale'],
['Socha Hai', 'HINDI', '2007', 'Pritam', 'NEtt_omY00k', 'Naqaab'],
['Nuvve Na Shwasa', 'TELUGU', '2003', 'M.M.Keeravani', 't_rrenXqs3o', 'Okariki Okaru', 16, 320],
['DHAN TE NAN', 'HINDI', '2009', 'Vishal Bhardwaj', 'BbHgbd6qWNk', 'Kaminey'],
['Oka Maru', 'TELUGU', '2005', 'Harris Jayaraj', 'UWOkoxNks4g', 'Ghajini', 0, 350],
['Rose Rose', 'HINDI', '2007', 'Pritam', '2u2w8Gns20Y', 'Naqaab'],
['Ek Din Teri Raahon Mein', 'HINDI', '2007', 'Pritam', '4FC_WJjIsmk', 'Naqaab', 7, 283],
['Keep you much Longer', 'ENGLISH', '2008', 'Akon', '2e6kYfGeCmg', 'Freedom'],
['528491', 'ENGLISH', '-', 'Hans Zimmer ', 'NX7yOVVr_d0', 'Inception'],
['Nanu Preminchananu Maata ', 'TELUGU', '1999', 'AR RAHMAN', 'hAFVqHNekRQ', 'Jodi'],
['Kaalaiyil dhinamum bgm 2', 'TAMIL', '2004', 'A. R. Rahman', '17n2dLNm0aQ', 'New'],
['Heera', 'HINDI', '2014', 'AR RAHMAN', 'xn1nhR5Ayx0', 'Highway', 0, 270],
['Konte Chooputho', 'TELUGU', '2008', 'James Vasanthan', 'dhIrlFsz50w', 'Anantapuram 1980', 0, 321],
['Iris - Live', 'ENGLISH', '1998', 'Goo Goo Dolls', '_HZM0QiuUS8', '-'],
['Neelo Jarige', 'TELUGU', '2005', 'Mani Sharma', 'yaLoClYM8vc', 'Balu'],
['Nillayo ', 'HINDI', '2015', 'Mithoon', 'WMnLR2tADOI', ' Khamoshiyan'],
['Khadgam BGM 1', 'TELUGU', '2002', ' Devi Sri Prasad', 'aiIw-L_wb1w 1', 'Khadgam', 0, 350],
['Remo - BGM 10', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 10', 'Remo', 840, 908],
['Seven Nation Army', 'ENGLISH', '-', 'The White Stripes', 'RDuzszjrdcc', '-'],
['Shukran Allah', 'HINDI', '2009', 'Salim–Sulaiman', 'vbW_zqQVZE0', 'Kurbaan', 0, 280],
['Udayinchina', 'TELUGU', '2002', 'Devi Sri Prasad', 'tNdlz0LmVTk', 'Kalusukovalani', 6, 325],
['Inconsolable', 'ENGLISH', '2007', 'Backstreet Boys', 'c3955-vJ5Nw', 'Unbreakable', 0, 214],
['Boulevard Of Broken Dreams', 'ENGLISH', '2004', 'Green Day', '0LJCEROH4u0', 'American Idiot'],
['Kottaga BGM 2', 'TELUGU', '1988', 'IlayaRaaja', 's9h0kmdSXx8 2', 'Swarna Kamalam', 135],
['Beat it', 'TAMIL', '2008', 'A. R. Rahman', '8fO8jVZ3T9g', 'Sakkarakatti'],
['Ik Mulaqaat', 'TELUGU', '2018', 'Vishal Chandrasekhar', 'bqK2uLv_6I8', 'Padi Padi Leche Manasu'],
['Moonu BGM 2', 'TAMIL', '2011', 'Anirudh', 'OK37VeSiSyk', 'Moonu'],
['Qismat', 'PUNJABI', '1996', 'A.R Rahman', 'OrQz7sieV2E', 'Bharateeyudu'],
['Nachave Nizam Pori ', 'TELUGU', '2004', 'Devi Sri Prasad', 'xR85zCreXUo', 'Varsham', 5, 255],
['The Breakup Song', 'HINDI', '2016', 'Pritam', 'kd5KqlmcHNo', 'Ae Dil Hai Mushkil'],
["I'm A Man", 'ENGLISH', '2007', 'Black Strobe', 'BWJuGYRW5_s', 'Burn Your Own Church'],
['PYAR KARKE', 'HINDI', '2006', 'Pritam', 'otV2SK65MmA', 'PYAR KE SIDE EFFECTS'],
['Ullu Ka Pattha', 'HINDI', '2017', 'Pritam', '8cs10z1CzxE', 'Jagga Jasoos', 0, 204],
['Purple Yellow Red & Blue', 'ENGLISH', '2013', 'Portugal. The Man', 'q5x_YoweEg8', 'Evil Friends', 0, 248],
['Good Riddance', ' ENGLISH', '1997', ' Green Day', 'Nwhyj0zcD7M', ' Nimrod'],
['Monalisa', 'TAMIL', '1996', 'AR RAHMAN', 'rmkG6gZw__w', 'Mr Romeo'],
['Sada Siva', 'TELUGU', '2010', 'Mani Sharma', 'Zfz8HUw4Ab8', 'Khaaleja', 0, 264],
['Yamaha Nagari', 'TELUGU', '1998', 'Mani Sharma', 'L2E5G4koMnk', 'Choodalani Undi', 0, 375],
['Escape', 'ENGLISH', '2001', 'Enrique Iglesias', 'X5lRRZtjpFU', 'Escape'],
['Nallai Allai', 'TAMIL', '2017', 'AR Rahman', 'Ixiq9ysnqos', 'Kaatru Veliyidai', 0, 213],
['Neduvali', 'TELUGU', '2011', 'Mani Sharma', 'I8PWsc9P1nM', 'Teenmaar'],
['Perfect Duet', 'ENGLISH', '2017', 'Ed Sheeran - Beyoncé', '817P8W8-mGE', 'Divide', 0, 254],
['Wicked Ones', 'ENGLISH', '2014', 'Dorothy', 'kOngAfP_2OE', 'DOROTHY', 0, 170],
['A Thousand Miles', 'HINDI', '2007', 'Pritam', 'KdMBLxqntH0', 'Awarapan'],
['Preminche Premava', 'TELUGU', '2006', 'A. R. Rahman', 'FR3Febey_lI', 'Nuvvu Nenu Prema', 16, 364],
['Bole Chudiyan', 'HINDI', '2001', 'Jatin Lalit', 'k8Yn6c7k7Qw', 'Kabhi Khushi Kabhie Gham', 0, 398],
['Shake It Off', 'ENGLISH', '2014', 'Taylor Swift', 'nfWlot6h_JM', ' 1989', 0, 231],
['Hey Ya!', 'HINDI', '2010', 'Shan-Ehsan-Loy.', 'wLH4rrmIT60', 'Karthik Calling Karthik', 0, 253],
['Kaise Mein Kahun Tujhse', 'HINDI', '2001', 'Harris Jayaraj ', 'fWN1npkMuBk', 'Rehna Hai Tere Dil Mein'],
['Victory', 'ENGLISH', '2000', 'Hans Zimmer ', 'OLTZbJMQiD4', 'Gladiator'],
['Tu Hai BGM - All Versions', 'TAMIL', '2020', 'A. R. Rahman', 'AZ50nf3BxsU', 'MohenjoDaro'],
['Gundu Sudhi', 'TELUGU', '2005', 'M. M. Keeravani', 'qwxmSWBkwzY', 'Chatrapathi', 0, 255],
['Kadhulu Kadhulu', 'TELUGU', '2007', 'Harris Jayaraj', 'qYU7Hd3CwfQ', 'Munna'],
['Matarani Mounamidi', 'TELUGU', '1988', 'IlayaRaaja', 'kzAs4ErMs08', 'Maharshi', 0, 273],
['O My Friend', 'TELUGU', '2007', 'Mickey J. Meyer', 'nYVrSXmdKNk', 'Happy Days', 0, 295],
['I love You', 'HINDI', '2011', 'Pritam', 'UKQ0BuFErq4', 'Bodyguard', 0, 254],
['Chain (Acoustic)', 'JAPANESE', '2006', 'BACK-ON', 'HEZPvtxDj4A', 'Chain', 0, 200],
['Am I Wrong', 'ENGLISH', '2013', 'Nico & Vinz', '3oUPGbRazaA', 'Am I Wrong', 0, 244],
['Lighters', 'ENGLISH', '2011', 'Bad Meets Evil', 'qJHiMGomJ_A', 'Hell: The Sequel'],
['Naa Kosame', 'TELUGU', '2001', 'Mani Sharma', '0gU9kx2hDVE', 'Subbu', 0, 235],
['Kaarkuzhal', 'ENGLISH', '2010', 'Charlie Puth', '3VBLaFC8QfQ', 'See You Again'],
['Prema Prema', 'TELUGU', '1996', 'A R Rahman', 'Jo-Wb7gpQOY', 'Prema Desam', 4, 342],
['Bachna Ae Haseeno', 'HINDI', '2008', 'Vishal-Shekhar', 'bh2O4oyB1UM', 'Bachna Ae Haseeno', 0, 199],
['Takkunu Takkunu', 'TAMIL', '2019', 'Hiphop Tamizha', '7pVoLXgQkok', 'Mr.Local', 0, 215],
['Me And My Broken Heart', 'ENGLISH', '2014', 'Push Baby', '7JJfJgyHYwU', 'Me And My Broken Heart'],
['Ee Chota Nuvvunna', 'TELUGU', '2003', 'Ramana Gogula', '24Zim7gg484', 'Johnny', 0, 248],
['Katravai Patravai', 'TAMIL', '2018', 'Santhosh Narayanan', '2WG--2Z5uNo', 'Kaala', 5, 224],
['Kaho Na Kaho', 'HINDI', '2002', 'Anu Malik', '8jKlI0OcspY', 'Murder'],
['Udd Gaye', 'HINDI', '2017', 'Ritviz', 'v2-9rIL_f4w', 'Udd Gaye (Bacardi House Party Sessions)'],
['Bulleya', 'HINDI', '2016', 'Pritam', 'zJ0KO4Kec3w', 'Ae Dil Hai Mushkil', 0, 344],
['7G Rainbow Colony BGM 2', 'TAMIL', '2007', 'Yuvan Shankar Raja', 'xAx8GwWLSlU', '7G Rainbow Colony'],
['Padana Teeyaga', 'TELUGU', '2002', 'Harris Jayaraj', 'rtvSNC_82Lk', 'Vaasu', 5, 326],
['Sa Ri Ga Me Telugu', 'TELUGU', '2003', 'A R Rahman', 'QVElQZalRDU 2', 'Boys', 992, 1356],
['Wake Me up When September Ends', 'ENGLISH', '2004', 'Green Day', 'ulRXvH8VOl8', 'American Idiot', 0, 280],
['Moonu BGM 1', 'TAMIL', '2011', 'Anirudh', 'BK9NQ7PuG3I', 'Moonu'],
['Anjali Anjali', 'TELUGU', '1990', 'IlayaRaaja', '_sgC71Qr2Hs', 'Anjali', 19, 350],
['Lucky Lucky', 'TELUGU', '1997', 'AR RAHMAN', 'WvLyyBJ8zsA', 'Ratchagan', 6, 364],
['My Last', 'ENGLISH', '2011', 'Big Sean ft. Chris Brown', 'EeTPi2a2Ld4', '-', 50, 300],
['Why so Serious?', 'ENGLISH', '2008', 'Hans Zimmer ', 's2Lo047gK0U', 'The Dark Knight'],
['Kadhalikkum Pennin', 'TAMIL', '1994', 'A R Rahman', 'MxR4WXFyYNE', 'Kaadhalan', 0, 285],
['Varshinche Meghamla', 'TELUGU', '2001', 'Harris Jayaraj', 'jkzFHXeIwSQ', 'Cheli', 0],
['Aap Ki Kashish', 'HINDI', '2006', 'Shankar-Ehsaan-Loy', 'qfbGAWK-SGM', 'Kabhi Alvida Naa Kehna'],
['Gharshana BGM 3', 'TELUGU', '2004', 'Harris Jayaraj', 'Mjq9GQdiY04', 'Gharshana'],
['All I Want (Part 2)', ' ENGLISH', '2014', 'Kodaline', 'RzuXZfKg2YM', 'In a Perfect World', 0, 308],
['The Nights', ' ENGLISH', '2013', 'Avicii', '2S0QhGGO1gQ', 'True'],
['Manmohini Morey', 'HINDI', '2012', 'Amit Trivedi', 'Yd5JSoV8CtQ', 'Ishaqzaade'],
['Californication', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'QkhybUXZqWQ', 'Pirates Of The Caribbean', 0, 322],
['Kona Semallo', 'TELUGU', '2001', 'Suresh Peters', 'l31KWMp-ktQ', 'Hanuman Junction'],
['Tere Bin', 'HINDI', '2007', 'Pritam', 'aeNq_hB9TXI', 'Awarapan'],
['Destroyer Of Worlds', 'ENGLISH', '2000', 'Hans Zimmer ', 'Pswx6OQp1Ks', 'Gladiator'],
['Enna Sona', 'HINDI', '2010', 'A R Rahman', 'ofTxceS4wLI', '-'],
['Po Indru Neeyaga', 'TAMIL', '2014', 'Anirudh ', 'ADJSD_oh5nU', 'Velai Illa Pattadhaari', 0, 218],
['DIL CHEEZ TUJHE DEDI', 'HINDI', '2016', 'Ankit Tiwari', 'Q-OU6l16YRo', 'AIRLIFT'],
['Dusk Till Dawn', 'ENGLISH', '2017', ' Zayn Malik', 'IgGylfWRI3I', 'Icarus Falls', 0, 260],
['Saibo', 'HINDI', '2011', 'Sachin-Jigar', 'JeiZTnRfrbM', 'Shor in the City', 0, 190],
['Manmadhan BGM 2', 'TAMIL', '2005', 'Harris Jayaraj', 'KYYHOkku5FU 2', 'Ghajini', 446, 496],
['Arjun Reddy bgm 2', 'TELUGU', '2017', 'Radhan', 'KpKQTAW-3K0 2', 'Arjun Reddy', 428, 500],
['Maate Raani', 'TELUGU', '1990', 'IlayaRaaja', 'ZhDdEY5E-CU', 'O Papa Lali', 0, 260],
['Chitapata Chinukulu', 'TELUGU', '2003', 'Kalyani Malik', 'GpttMqSGiOM', 'Aithe', 6, 305],
['Messi GOAT', 'HINDI', '2014', 'A.R Rahman', '-B42YIFbRas', ' Highway'],
['Nenjukkule Guitar BGM', 'TAMIL', '2013', 'A.R Rahman', 'pamF0nFkM6A', 'Kadal'],
['Prema | Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'JFaEhjd0dRg', 'Songs from the Big Chair'],
['I am a rebel', 'TAMIL', '2006', 'AR RAHMAN', 'Exrs85DTD0A', 'Rang De Basanti', 443, 660],
['Aadha Ishq', 'HINDI', '2010', 'Salim–Sulaiman', 'AWjQgUDfZG0', 'Band Baaja Baaraat'],
['Ik Junoon (Paint It Red)', 'HINDI', '2011', 'Shankar-Ehsaan-Loy', 'ivUXoV0qLpE', 'Zindagi Na Milegi Dobara', 0, 294],
['Jesus Walks', 'ENGLISH', '2004', 'Kanye West', 'f9wJBdFy6sQ', 'The College Dropout'],
['Andhala rakshasive', 'TELUGU', '1999', 'A.R Rahman', 'o3gheB5EmBA', 'oke okkadu', 0, 366],
['Remo - BGM 13', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 13', 'Remo', 1034, 1122],
['Ai Se Eu Te Pego', 'ENGLISH', '2005', 'Bon Jovi', 'hcm55lU9knw', 'Oral Fixation Vol. 2'],
['Jeans BGM 4', 'TAMIL', '1998', 'AR RAHMAN', 'K_SMe3U9l34 4', 'Jeans', 143, 220],
['Now We Are Free ', 'ENGLISH', '2000', 'Hans Zimmer ', 'bxZx6NHkRyE', 'Gladiator'],
['Allah Maaf Kare', 'HINDI', '2011', 'Pritam', 'BhtHOzkQRpI', 'Desi Boyz'],
['Munbe Vaa Violin', 'TAMIL', '2006', 'A. R. Rahman', 'cBlt5xg_y5g', 'Sillunu Oru Kaadhal'],
['Mandaara', 'TELUGU', '2018', 'S. Thaman', 'fdgAMPzwqnU', 'Bhaagamathie', 4, 272],
['Judai', 'HINDI', '2008', 'Pritam', '_yOrZ9dKAzI', 'Jannat', 0, 300],
['Magic', 'ENGLISH', '2014', 'Coldplay', '1PvBc2TOpE4', 'A Head Full of Dreams ', 0, 281],
['Mirrors', 'ENGLISH', '2013', 'Justin Timberlake', '_1y7F0ZzXYo', 'The 20/20 Experience'],
['Padayappa Theme 3', 'TAMIL', '1999', 'A R Rahman', 'eguXwwDSQNM 3', 'Padayappa', 964, 995],
['Innum Konjam Naeram', 'TAMIL', '2013', 'AR Rahman', 'jqCh3fSNog4', 'Maryan', 0, 308],
['Timber', ' ENGLISH', '2013', ' Kesha Pitbull', '5HnUAOeXI6c', 'Meltdown'],
['MAR JAWAAN', 'HINDI', '2008', 'Salim–Sulaiman', 'SJPo9PRSI2E', 'Fashion'],
['Emitemitemito', 'TELUGU', '2017', 'Radhan', 'T6p9NmQm5zA', 'Arjun Reddy', 4, 204],
['Wake Up Sid', 'HINDI', '2009', 'Shankar-Ehsaan-Loy', 'Jqzz8K6mMVs', 'Wake Up Sid', 2],
['Main Tera Boyfriend', 'HINDI', '2017', 'Meet Bros', 'hQqHFJdSq0M', 'Raabta', 0, 274],
['Raanjhanaa', 'HINDI', '2013', 'AR Rahman', 'OsTGp5n6w5E', 'Raanjhanaa'],
['María', 'SPANISH', '1995', 'Ricky Martin', 'vCEvCXuglqo', 'A Medio Vivir'],
['Azhagiye', 'TAMIL', '2017', 'AR Rahman', 'TFUBlO-zJGg', 'Kaatru Veliyidai', 0, 224],
['Ride', 'ENGLISH', '2005', 'Bon Jovi', 'jHKQof8WIlI', 'Oral Fixation Vol. 2'],
['Neruppey', 'TAMIL', '2006', 'Harris Jayaraj', '1x7QFlrQncM', 'Vettaiyaadu Vilaiyaadu', 0, 286],
['Bas Ek Pal', 'HINDI', '2006', 'Mithoon', '0lladZqixyk', 'Bas Ek Pal', 0, 630],
['Kaka kaka - Surya - encounter', 'TAMIL', '2003', 'Harris Jayaraj', 'IsP3s21qw0Y', 'Kaakha Kaakha'],
['Mila Mila', 'TELUGU', '2005', 'Sandeep Chawtha', 'etkHaaW7GNQ', 'Super', 0, 326],
['Canon in D Cello-Guitar Duo', 'HINDI', '2009', 'Pritam', '-mb9jlbiVF4', 'Billu'],
['In Da Club violin cover', 'ENGLISH', '2012', 'Leonard Cohen', 'DHuVNtpd4pE'],
['Made to Love', 'ENGLISH', '2012', 'Imagine Dragons', 'S3vx_nagYew', 'Continued Silence EP'],
['Stacys Mom', 'TAMIL', '2008', 'A. R. Rahman', 'q0y10IQ2hfw', 'Sakkarakatti'],
['Sonapareeya', 'TAMIL', '2013', 'AR Rahman', '05VpS1L5_hc', 'Maryan', 0, 246],
['Vayyarala Jabilli', 'TELUGU', '2011', 'Mani Sharma', 'z4KYMafu5VY', 'Teenmaar', 0, 320],
['Innum Konjam BGM Maryan falls in Love ', 'TAMIL', '2013', 'AR Rahman', 'ddT9h3tCKAE', 'Maryan'],
['Egirey Mabbulona', 'TELUGU', '2006', 'Yuvan Shankar Raja', 'W-NFkJat4XA', 'Happy', 0, 234],
['Pedave Palikina', 'TELUGU', '2004', 'A. R. Rahman', 'qvTtvP5Neqg', 'Naani', 4, 262],
['Falcon Eye', 'ENGLISH', '2017', 'Off Bloom', 'VASKfvfTePI', 'Falcon Eye', 0, 226],
['Going In Blind', 'ENGLISH', '2001', 'P.O.D', 'VH4YfGDild0', 'Greatest Hits: The Atlantic Years', 0, 266],
['Minnale BGM 3', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 3', 'Minnale', 533, 596],
['Team', 'ENGLISH', '2013', 'Lorde', 'zTz_qdgEgMg', 'Pure Heroine', 0, 190],
['Happy Bit', 'TELUGU', '2006', 'Yuvan Shankar Raja', 'NRdQ-wboFdQ', 'Happy', 3],
['Pachani Chilukalu', 'TELUGU', '1996', 'A.R Rahman', '2QAMn4EvgBY', 'Bharateeyudu', 0, 345],
['One Day At A Time', 'ENGLISH', '2010', 'Enrique Iglesias - Akon', 'QxblocPxRX0', 'Euphoria'],
['Get Low', 'ENGLISH', '2014', 'Dillon Francis, DJ Snake', '12CeaxLiMgE', 'Get Low'],
['Kaise Hua Cover', 'HINDI', '2019', 'Mithoon', 'PW-KoLuLsSE', 'Kabir Singh'],
['Nenjae Yezhu', 'TAMIL', '2013', 'AR Rahman', 'SWnVTMdQVbk', 'Maryan', 9],
['The Sounds of Silence ', 'ENGLISH', '-', 'Billy Joel', 'bjkPQYWNTlg', '-'],
['Kalusukovalani BGM 1', 'TELUGU', '2002', 'Devi Sri Prasad', '-NZ8dxpfqbw', 'Kalusukovalani'],
['AAO MILO CHALO', 'HINDI', '2007', 'Pritam', 'U0JYkRqU6eY', 'Jab We Met'],
['Abhi Mujh Mein Kahin BGM', 'HINDI', '2012', 'Ajay-Atul', '1FmPiwz6IvA', 'Agneepath', 264],
['Yaanji', 'TAMIL', '2017', 'Anirudh ', 'bWK3EuroEk4', 'Vikram Vedha', 0, 267],
['I Knew You Were Trouble', 'ENGLISH', '2012', 'Taylor Swift', 'VmBoTeLsKfs', ' Red (Big Machine Radio Release Special)', 0, 217],
['Why do we fall', 'ENGLISH', '2008', 'Hans Zimmer ', '0TcolckMrDw', 'The Dark Knight Rises'],
['Protectors of the Earth', 'ENGLISH', '2014', 'Two Steps from Hell', 'uEEyhDcSXko', 'Invincible', 0, 166],
['Manmadhan Theme', 'TAMIL', '2004', 'Yuvan Shankar Raja', 'xflC7bCgOvU', 'Manmadha'],
['Ishq wala love', 'HINDI', '2007', 'Pritam', 'ruTBVfZVKg0', 'Awarapan'],
['Chinni chinni aasa', 'Telugu', '1992', 'A R Rahman', 'SBTi2vtJc1c', 'Roja'],
['Akasam Ammaiyaite', 'HINDI', '2007', 'Pritam', 'p6Ejrw6VZKk', 'Awarapan'],
['Ullaallaa', 'TAMIL', '2019', 'Anirudh', 'UvQ9y4w8unE', 'Petta', 0, 293],
['Cheap Thrills', 'ENGLISH', '2016', 'Sia', 'nEIHHydwec8', 'This Is Acting', 0, 218],
['Eenie Meenie', 'ENGLISH', '2009', 'Justin Bieber', 'prmmCg5bKxA', 'My Worlds', 0, 320],
['Elay-Bit', 'TAMIL', '2020', 'A. R. Rahman', 'TDsBz8_UvdY', 'Kadal', 0, 60],
['Sakhiyan 2.0', 'TELUGU', '1996', 'A.R Rahman', '55t306Hgg58', 'Bharateeyudu'],
['Manam Virumbuthe', 'TELUGU', 'Tamil', 'Deva', 'qAa2DJiFzGA', 'Naerukku Naer'],
['Rabba Mein Toh Mar Gaya', 'HINDI', '2013', 'Pritam', 'c7POv4qiZLM', 'Mausam'],
['Beautiful', 'ENGLISH', '2009', 'Eminem', 'F4yb0CBd4I4', 'Relapse'],
['Unnale Unnale BGM 1', 'TAMIL', '2001', 'Harris Jayaraj', 'w9WKEMkAABg 1', 'Unnale Unnale', 748, 786],
['Ghal Ghal', 'TELUGU', '2005', 'Devi Sri Prasad', 'JOCeKMirVIk', 'Nuvvostanante Nenoddantana', 0, 318],
['KABHI JO BAADAL BARSE', 'HINDI', '2013', 'Shaarib–Toshi', 'qH1eRWlJpsY', 'Jackpot'],
['MAVELE MAVELE', 'HINDI', '2019', 'Beethoven', 'Y_gJHhbUCLI', 'Kabir Singh'],
['Kolaveri-R&B', 'ENGLISH', '2011', 'Arjun ', 'o6gHlK-Mm2Y', '3', 5, 210],
['Niluvaddham Ninu', 'TELUGU', '2005', 'Devi Sri Prasad', '6ehEgqavu7I', 'Nuvvostanante Nenoddantana'],
['Chahoon Bhi Toh', 'HINDI', '2011', 'Harris Jayaraj', 'SIfEDWGVwLs', 'Force', 0, 290],
['Prema parichayame', 'TAMIL', '2015', 'A R Rahman', '6uWr_sZFxbg', '24'],
['Aerials', 'TAMIL', '2008', 'A. R. Rahman', 'zk62uUqcNyo', 'Sakkarakatti'],
['Another way to die', 'TELUGU', '1996', 'A R Rahman', '4_B4PtwE-QA', 'Prema Desam'],
['Jadu Hai Nasha Hai', 'HINDI', '2003', 'M. M. Keeravani', 'Iwa0Z9roM7g', 'Jism', 0, 324],
['Bulleya', 'HINDI', '2016', 'Pritam', 'sntrCk3Ym-Q', 'Ae Dil Hai Mushkil'],
['Dil Diyyan Veena', 'HINDI', '2012', 'Ajay-Atul', '6RAo7B5Ev8E', 'Agneepath'],
['Annamalai Meets Petta', 'TAMIL', '2019', 'Anirudh', 'ESDcmnScIPQ', 'Petta'],
['Gharshana BGM 4', 'TELUGU', '2004', 'Harris Jayaraj', '69H3VMkYlFo', 'Gharshana', 354, 401],
['Taal Taal Se Taal (Western)', 'HINDI', '1999', 'AR RAHMAN', 'MelMTp_dxNQ', 'Taal', 0, 150],
['Guzaarish', 'HINDI', '2008', 'A. R. Rahman', 'clzuRgaV5dw', 'Ghajini', 0, 324],
['Ye Cheekati', 'TELUGU', '2007', 'Mickey J. Meyer', 'YXcEulXAfNM', 'Happy Days', 0, 276],
['Okkadu BGM 1', 'TELUGU', '2003', 'Mani Sharma', '_Uq7qDhbHsU', 'Okkadu'],
['Mental Manadhil', 'TAMIL', '2015', 'A. R. Rahman', 'Wp05ZCG3yMk', 'OK Kanmani', 0, 204],
['AanandamMadike', 'HINDI', '2007', 'Pritam', 'vwvZtkZy9Z8', 'Awarapan'],
['Yaarumilla', 'TAMIL', '2014', 'A. R. Rahman', 'idZ-5amTsHA', 'Kaaviyathalaivan'],
['Jeans BGM 5', 'TAMIL', '1998', 'AR RAHMAN', 'K_SMe3U9l34 5', 'Jeans', 216, 264],
['O Rendu Prema Meghaalila Reprise', 'TELUGU', '1996', 'A R Rahman', 'zZw3ECvdCM8', 'Prema Desam'],
['Jaamu Raathiri Rekindled', 'ENGLISH', '1985', 'Tears for Fears', '_cwU4Cg0Tkg', 'Songs from the Big Chair'],
['Whistle', 'ENGLISH', '2012', 'Flo Rida', 'mRaffkti2us', 'Whistle'],
['My Heart Will Go On', 'ENGLISH', '2013', 'Celine Dion', 'pZk_YV7yqeA', "Let's talk about love", 0, 304],
['Boochade Boochade', 'TELUGU', '2014', 'S. Thaman', 'P0dHPM0Gzzc', 'Race Gurram', 4, 260],
['Oh Maria', 'TAMIL', '1999', 'A. R. Rahman', 'L2J6Vj5TlNE', 'Kadhalar Dhinam', 34],
["DJ Got Us Fallin' In Love", 'ENGLISH', '2010', 'Usher', 'C-dvTjK_07c', 'Versus', 20, 240],
['Haan Tu Hain', 'HINDI', '2008', 'Pritam', 'plcI5i3uuTs', 'Jannat', 0, 322],
['Em Jaruguthondi', 'TELUGU', '2009', 'Vijay Antony', 'trqbFC1oMDI', 'Mahatma', 0, 292],
['Bulla Ki Jaana Main Kaun', 'HINDI', '2004', 'Rabbi Shergill', 'gbpdE8n_QWo', 'Rabbi'],
['Kaaka Kaaka BGM', 'TAMIL', '2003', 'Harris Jayaraj', 'KsuuNxigjoU', 'Kaakha Kaakha'],
['Tera Hone Laga Hoon', 'HINDI', '2009', 'Pritam', 'rTuxUAuJRyY', 'Ajab Prem Ki Ghazab Kahani', 0, 294],
['Jeans Theme BGM', 'TAMIL', '1998', 'AR RAHMAN', 'YzyPuGjLnsc', 'Jeans'],
['Pithamagan 3', 'TAMIL', '2015', 'Anirudh', '_rpOKkKXFqg', 'Maari', 251, 278],
['So What', 'ENGLISH', '2008', ' Pink', 'GRwTi-uSiQg', 'Funhouse', 0, 214],
['ROCK THA PARTY extended', 'HINDI', '2016', 'BOMBAY ROCKERS', 's2WNt46i9Cs', 'ROCKY HANDSOME'],
['Experience', 'HINDI', '2019', 'Mithoon', '1e9B31FLT-s', 'Kabir Singh'],
['Nenani Neevani', 'TELUGU', '2008', 'Mickey J. Meyer', 'x6HDlIGRk10', 'Kothabangarulokam', 16, 324],
['Life of Pazham', 'TELUGU', '2001', 'Mani Sharma', 'H3yKzgY2J0Q', 'Kushi'],
['Ghajini BGM 1', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 1', 'Ghajini', 586, 695],
['Yeh DOORIYAN', 'HINDI', '2020', 'Pritam', 'WnnLiBgzsyM', 'Love Aaj Kal 2020', 0, 234],
['Thalapathi BGM', 'TAMIL', '1991', 'IlayaRaaja', 'rZzTVf8udgw', 'Thalapathi', 0, 55],
['See You Again Taylor Swift', 'ENGLISH', '2010', 'Charlie Puth', 'UxsxvOCplB8', 'See You Again'],
['Chingaara kinnaram', 'MALAYALAM', '1994', 'S. P. Venkatesh', 'YdH97HGAoEU', 'Minnaram', 0, 265],
['Rishte Naate', 'HINDI', '2009', 'Pritam', 'c504EGCwQlU', 'De Dana Dan', 5, 282],
['Munbe vaa BGM - 4', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 5', 'Sillunu Oru Kaadhal', 78, 128],
['Pacha Velugu', 'TELUGU', '2006', 'Harris Jayaraj ', 'Wwhx8ED8hUQ', 'Raghavan', 0, 350],
['Vaathi coming', 'HINDI', '2007', 'A. R. Rahman', 'BMFxVtsXgRk', 'Guru'],
['Alisha', 'TELUGU', '2011', 'Mani Sharma', 'zyEyvmORHI8', 'Teenmaar'],
['Rangeelo Maro Dholna ', 'HINDI', '2015', 'Pritam', 'jmb82TtEe7g', 'Dilwale'],
['Pompeii', 'ENGLISH', '2013', 'Bastille', 'F90Cw4l-8NY', 'Bad Blood', 0, 226],
['Ik Vaari Aa', 'HINDI', '2017', 'Pritam', 'vL14wk2cj6U', 'Raabta', 0, 272],
['Chandamama', 'TELUGU', '2007', 'Devi Sri Prasad', 'nSW0ksiTWyQ', 'Shankardada Zindabad', 0, 220],
['Paruvam Vanaga', 'Telugu', '1992', 'A R Rahman', 'd3otc4j-QgU', 'Roja'],
['Oh Penne', 'TAMIL', '2013', 'Anirudh', 'C7MAqIcM-Sg', 'Vanakkam Chennai'],
['Channa Mereya Bgm', 'HINDI', '2016', 'Pritam', '2E2EybsCVEE', 'Ae Dil Hai Mushkil'],
['I BGM 1', 'TAMIL', '2017', 'AR Rahman', 'HTm9MIGy0Lw', 'I'],
['Pillaa Raa Flute', 'TELUGU', '2018', 'Chaitan Bharadwaj - Lalit ', 'V4-79PWWcBc', 'RX 100', 5, 170],
['Manmadhan BGM 1', 'TAMIL', '2005', 'Harris Jayaraj', 'KYYHOkku5FU 1', 'Ghajini', 348, 446],
['Manmadhan BGM 3', 'TAMIL', '2005', 'Harris Jayaraj', 'KYYHOkku5FU 3', 'Ghajini', 497, 551],
['New York Nagaram', 'TELUGU', '2006', 'A. R. Rahman', 'CMNHVLbC9As', 'Nuvvu Nenu Prema', 0, 376],
['Pithamangan BGM 1', 'HINDI', '2020', 'Pritam', 'KUi_eW5UL1U 1', 'Love Aaj Kal 2020', 992, 1082],
['Ra Ra Bangaram', 'HINDI', '2007', 'A. R. Rahman', 'gFJNdLO4w6I', 'Guru'],
['Burn', 'ENGLISH', '2013', 'Ellie Goulding', 'J5f27dW_AS0', 'Halcyon Days', 0, 231],
['Boro Boro', 'Persian', '2004', 'Arash', 'J1CJIevhUr8', 'Boro Boro', 0, 188],
['Ale ale Telugu', 'TELUGU', '2003', 'A R Rahman', 'QVElQZalRDU 1', 'Boys', 606, 990],
['Lonely day', 'ENGLISH', '2005', 'System Of A Down', '9VBiJvJfC0Q', 'Hypnotize'],
['Balam Pichkari', 'HINDI', '2013', 'Pritam', '4hoiwi3tE6w', 'Yeh Jawaani Hai Deewani', 0, 166],
['Hold my hand', 'ENGLISH', '2010', 'Michael Jackson - Akon', '-oCCnxBos10', 'Jackson’s Paradise', 9, 214],
['Suo Gân', 'TAMIL', '2008', 'A. R. Rahman', 'N_3_skKeGCc', 'Sakkarakatti'],
['Aho! Oka Manasuku', 'TELUGU', '1993', 'M.M.Keeravani', 'ltyBgb205pw', 'Allari Priyudu'],
['Now we are free cover', 'HINDI', '2009', 'Pritam', 'fF53Bt-XPio', 'Billu'],
['All I Want (Part 1)', ' ENGLISH', '2014', 'Kodaline', 'mtf7hC17IBM', 'In a Perfect World'],
['Maahi - Rock With Me', 'HINDI', '2009', 'Shaarib-Toshi', 'xx9C4nMUWXg', 'Raaz 2', 0, 285],
['Habibi', 'HINDI', '2020', 'Pritam', 'zLF12X4OD48', 'Love Aaj Kal 2020'],
['Life In Color', 'ENGLISH', '2013', 'OneRepublic', '-c8m66tf7Rk', 'Native'],
['Remo - BGM 4', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 4', 'Remo', 268, 306],
['Khadgam BGM 2', 'TELUGU', '2002', ' Devi Sri Prasad', 'aiIw-L_wb1w 2', 'Khadgam', 44, 96],
['Remo - BGM 7', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 7', 'Remo', 440, 580],
['Try-Live', 'ENGLISH', '2012', ' Pink', 'ivPEKaBHjYA', 'The Truth About Love', 12, 252],
['Nenjukkule BGM', 'TAMIL', '2013', 'A.R Rahman', 'fo9g25gpD9Q', 'Kadal', 0, 93],
['AyPilla ', 'HINDI', '2017', 'Pritam', 'my4yAvsiLqM', 'Jab Harry Met Sejal'],
['Im the One', 'ENGLISH', '2011', 'One Direction', '158plNHX4vw', 'Up All Night'],
['Dont Look Back In Anger', 'HINDI', '2017', 'Pritam', '4G1BFFcEgpc', 'Jab Harry Met Sejal'],
['High Hopes', 'ENGLISH', '1994', 'Pink Floyd', '7jMlFXouPk8', 'The Division Bell', 0, 462],
['Tujhe Bhula Diya', 'HINDI', '2010', 'Vishal-Shekhar', 'o-9VdyXZKsQ', 'Anjaana Anjaani', 0, 275],
['Sarfira', 'HINDI', '2015', 'Shankar-Ehsaan-Loy', 'WMQIl6nAR00', 'Katti Batti', 0, 240],
['Ennamo Pannura', 'TAMIL', '2016', 'Radhan', 'S4WxvkXbay0', 'Valeba Raja'],
['Iris', 'ENGLISH', '1998', 'Goo Goo Dolls', 'CUbJQGqFoi0', 'City of Angels: Music from the Motion Picture', 0, 286],
['Not Afraid', 'ENGLISH', '2010', ' Eminem', 'j5-yKhDd64s', 'Road to Recovery: Withdrawal', 0, 240],
['Final Frontier', 'ENGLISH', '2014', 'Thomas Bergersen ', 'BAzCf0ascW8', 'Sun'],
['Manmadhudu BGM 1', 'TELUGU', '2002', ' Devi Sri Prasad', 'D4ozh46aZQ0', 'Manmadhudu BGMS', 4],
['Manassa', 'TELUGU', '2007', 'Harris Jayaraj', 'kZL_70CLyrg', 'Munna', 0, 346],
['Kamariya', 'HINDI', '2018', 'Lijo George - Dj Chetas', 'nfrfUSuFlow', 'Mitron', 0, 250],
['Panchi', 'HINDI', '2011', 'Jal featuring Quratulain Balouch', '4HCdGGag3no', '-'],
['Sadi Gali', 'HINDI', '2011', ' Krsna Solo', 'w_HaezV0DqI', 'Tanu Weds Manu'],
['Such Keh Raha Hai', 'HINDI', '2001', 'Harris Jayaraj ', 'Ad11XnLcG8U', 'Rehna Hai Tere Dil Mein', 0, 324],
['Desert Rain', 'TAMIL', '2008', 'A. R. Rahman', 'zZpjCtdY8-Q', 'Sakkarakatti'],
['Kadhal Anukkal', 'TAMIL', '2010', 'A. R. Rahman', 'rH7VvU34NIU', 'Robo'],
['Sillunu Oru Kadhal bgm 1', 'TAMIL', '2006', 'AR RAHMAN', '_oAgl-hZZ9U', 'Sillunu Oru Kadhal', 310, 387],
['Fur Elise', 'HINDI', '2019', 'Beethoven', 'f5ZravZc0Y8', 'Kabir Singh'],
['Vinnaithandi Varuvaya Title BGM', 'TAMIL', '2010', 'A R Rahman', 'fwM1v4-6KSo', 'Vinnaithaandi Varuvaayaa'],
['Ela Ela Naalo', 'TELUGU', '2011', 'Yuvan Shankar Raja', 'RrsKhq9TGhE', 'Panjaa', 0, 305],
['Ice Cream Penne', 'TAMIL', '2016', 'Radhan', 'XrJtigZWtwM', 'Valeba Raja'],
['PEE LOON', 'HINDI', '2010', 'Pritam', 'FYHKeHYlVA4', 'Once Upon a Time in Mumbaai', 0, 283],
['KABHI KABHI ADITI', 'HINDI', '2008', 'AR RAHMAN', 'r9eGi0rVxBw', 'JAANE TU... YA JAANE NA'],
['Ride wit me', 'TAMIL', '2005', 'Harris Jayaraj', 'FS6Fnhndo_8', 'Ghajini'],
['Thangamey BGM 1', 'TAMIL', '2015', 'Anirudh', 'PWksL4qlnAk', 'Naanum Rowdy Dhaan', 0, 78],
['Youth Of The Nation', 'ENGLISH', '2001', 'P.O.D', '1wNjBR_jZ9M', 'Satellite'],
['O Bekhabar', 'HINDI', '2010', 'Pritam', 'yidaL9xDlk8', 'Action Replayy', 0, 260],
['Danza Kuduro', 'Portuguese', '2010', 'Don Omar', 'YPlZ-nJewRE', 'Don Omar Presents: Meet the Orphans', 3, 200],
['Ammaho', 'TELUGU', '2008', 'Mani Sharma', 'CtyYmryumjQ', 'Kantri'],
['Masakali Original Theme', 'HINDI', '2020', 'AR Rahman', 'hHehTGHkDTU', 'Dilli 6'],
['Devathala Ninu', 'TELUGU', '2004', 'Vidyasagar', '2t_DvHgN8UA', 'Nenu', 0, 286],
['Sawan Aaya Hai - Unplugged', 'HINDI', '2017', 'Pritam', 'bWN0OGHiw90', 'Jab Harry Met Sejal'],
['Vaa Vaathi', 'ENGLISH', '2013', 'OneRepublic', '5BQQM4uvRkw', 'Native'],
['Show me the meaning', 'ENGLISH', '1999', ' Backstreet Boys', '38httsOpl8g', 'Millennium', 0, 233],
['Mental Madhilo', 'TAMIL', '2015', 'A. R. Rahman', 'mUekrcEu0Mc', 'OK Bangaram', 0, 202],
['Idhayathai Yedho', 'TAMIL', '2015', 'Harris Jayaraj', 'CDPULSnvLJw', 'Anegan'],
['Pray For Me Brother Instrumental', 'ENGLISH', '2007', 'A.R Rahman', 's9eAWMAqNnU', 'Pray For Me Brother', 0, 276],
['Manmadhan BGM 6', 'TAMIL', '2005', 'Harris Jayaraj', 'KYYHOkku5FU 6', 'Ghajini', 998, 1100],
['Remo - BGM 12', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 12', 'Remo', 976, 1030],
['Chiguraku Chatu', 'TELUGU', '2004', 'Mani Sharma', 'Q0cugVv3K2Y', 'Gudumba Shankar', 0, 308],
['VIP BGM 1', 'TAMIL', '2014', 'Anirudh ', '5J6RWxPNOiE', 'Velai Illa Pattadhaari', 572, 622],
['Priyatama Na Hrudayama | Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'KkwNuZdO-No', '-'],
['Icky Thump', 'ENGLISH', '-', 'The White Stripes', 'evypdgY6KpQ', '-'],
['Oorantha', 'TELUGU', '2007', 'Devi Sri Prasad', 'jFTWyjcOqzs', 'Shankardada Zindabad'],
['Love Cues of Samjhawan', 'HINDI', '2014', 'Sachin-Jigar', 'rlm0Ait_ujI', 'Humpty Sharma Ki'],
['Tujhe Kitna Chahne english cover', 'HINDI', '2019', 'Mithoon', '8-12-_G-hww', 'Kabir Singh'],
['Newyork Nagaram (All Versions)', 'TAMIL', '2020', 'A. R. Rahman', 'XgtkRItub3c', 'Sillunu Oru Kaadhal'],
['I BGM 2', 'TAMIL', '2017', 'AR Rahman', 'n67U3nl75pU', 'I'],
['DILWALE BGM', 'HINDI', '2016', 'Pritam', '3g7ahCk-flg', 'Dilwale', 0, 26],
['Munbe vaa BGM - 2', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 3', 'Sillunu Oru Kaadhal', 472, 642],
['Zaalima', 'HINDI', '2017', 'Ram Sampath', 'hhdSyBHuI88', 'Raees', 0, 296],
['Lukka Chuppi Flute Bit', 'HINDI', '2006', 'A. R. Rahman', '26P1JtzaGFg', 'Rang De Basanti'],
['Anjali Anjali ', 'TAMIL', '1994', 'AR RAHMAN', 'B_iuts4M5ZE', 'Duet', 0, 482],
['Nidhare Kala', 'TELUGU', '2008', 'Harris Jayaraj', 'ie4cp9GK75s', 'Surya S/o Krishnan'],
['Munbe vaa BGM - 7', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 8', 'Sillunu Oru Kaadhal', 258, 305],
['Flash travels back in time', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'juiULmf-AZ8', 'Pirates Of The Caribbean'],
['Everybody Wants To hammered dulcimer', 'ENGLISH', '1985', 'Tears for Fears', 'E6QOr-oCTwU', '-'],
['Kushi BGM 1', 'TELUGU', '2001', 'Mani Sharma', 'YQOBhKk9eBc', 'Kushi'],
['Remember The Name', 'ENGLISH', '2005', 'Fort Minor', 'IqB5L9_sfbQ', 'Petrified', 0, 227],
['Munbe Vaa Strings', 'TAMIL', '2006', 'A. R. Rahman', 'pvfyClEwnuI', 'Sillunu Oru Kaadhal', 0, 66],
['Hurt', 'ENGLISH', '2002', 'Johnny Cash', '8AHCfZTRGiI', 'American IV: The Man Comes Around', 0, 214],
['Airplanes', 'ENGLISH', '2010', ' B.o.B', 'Aebgf1_iryI', 'B.o.B Presents: The Adventures of Bobby Ra'],
['All Along The Watchtower - Guitar', 'ENGLISH', '2016', 'Jimi Hendrix', 'JZXRB9BuLhk', 'Electric Ladyland', 0, 244],
['Black Sheep - Live', 'ENGLISH', '2010', 'Metric', 'ArkDWrHmmXI', ' Scott Pilgrim vs. the World', 0, 237],
["Beachin'", 'ENGLISH', '2013', 'Jake Owen', 'VwgCBRj3dn4', 'Days of Gold', 9, 196],
['Despocito Remix', 'SPANGLISH', '2016', 'Luis Fonsi', 'whwe0KD_rGw', 'Vida', 0, 228],
['Latoo', 'HINDI', '2011', ' Krsna Solo', 'ORiC-DAahuM', 'Tanu Weds Manu'],
['O Sona', 'TELUGU', '1999', 'Deva', '5X-NctqvcBU', 'Vaali', 0, 242],
['Premiste BGM', 'HINDI', '2017', 'Pritam', 'Z3qBYFUJImY', 'Jab Harry Met Sejal', 0, 60],
['June Ponal', 'TAMIL', '2007', 'Harris Jayaraj', '7dybWlnGHHs', 'Unnale Unnale'],
['Stereo Love', 'TAMIL', '2008', 'A. R. Rahman', 'y9Kqb2z9Lzs', 'Sakkarakatti'],
['Undiporaadhey', 'TELUGU', '2018', 'Radhan', 'lewVFlngGCk', 'Hushaaru', 4, 176],
['Titanium - Mike Dawes', 'ENGLISH', '1985', 'Tears for Fears', 'EaI_wv3mKXI', '-'],
['Annul maelae bgm', 'TAMIL', '2008', 'Harris Jayaraj', '1qpSuyC2AS0', 'Vaaranam Aayiram'],
['Priyathama Flute BGM', 'TELUGU', '1990', 'IlayaRaaja', 'gJLE3bPBt4I', 'Jagadeka Veerudu Atiloka Sundari', 31, 69],
['Sadi Gali', 'HINDI', '2011', ' Krsna Solo', 'dAljcZtnDd4', 'Tanu Weds Manu'],
['Kal Ho Naa Ho', 'HINDI', '2003', 'Shankar-Ehsaan-Loy', 'r_oBWnz7wLE', 'Kal Ho Naa Ho', 0, 319],
['Andamaina Premarani', 'TELUGU', '1994', 'A. R. Rahman', 'kqXTFX4mNOg', 'Premikudu', 0, 286],
['All The Right Moves', 'ENGLISH', '2009', 'OneRepublic', 'qrOeGCJdZe4', 'Waking Up'],
['June Pothe', 'TELUGU', '2007', 'Harris Jayaraj', 'lqTiBstdBLk', ' Neevalle Neevalle', 0, 358],
['Unnattundi Gundey BGM 2', 'TELUGU', '2017', 'Gopi Sunder', 'z8N-KUVG9Uk', 'Ninnu Kori', 4, 84],
['Anisuthide cover', 'TELUGU', '2018', 'Vishal Chandrasekhar', 'lUyyFpGTJ4Q', 'Padi Padi Leche Manasu'],
['Is This Love', 'HINDI', '2008', 'Pritam', 'bCZ8F-QweM8', 'Kismat Konnection', 6, 332],
['Soch Na Sake', 'HINDI', '2015', 'Amaal Mallik', 'IQ-IzgNhvhg', 'AIRLIFT'],
['Yeke Yeke', 'HINDI', '2003', 'Shankar-Ehsaan-Loy', 'edSqW24kvpY', 'Kal Ho Naa Ho'],
['Gharshana BGM 2', 'TELUGU', '2004', 'Harris Jayaraj', 'f9f9LeZU0wE', 'Gharshana'],
['Silukku Marame ', 'TAMIL', '2008', 'D Imman ', 'jxrShUBmzM0', 'Paayum Puli', 0, 275],
['Kadavulae Vidai Reprise', 'TAMIL', '2017', 'Anirudh ', 'y6DOhiLT3xA', 'Rum', 0, 202],
['Remo - BGM 6', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 6', 'Remo', 372, 436],
['Wind Of Change', 'ENGLISH', '1990', 'Scorpions', 'n4RjJKxsamQ', 'Crazy World'],
['Manmadhan BGM 5', 'TAMIL', '2005', 'Harris Jayaraj', 'KYYHOkku5FU 5', 'Ghajini', 665, 763],
['Tujh Mein Rab Dikhta Hai Female', 'HINDI', '2008', 'Salim-Sulaiman', 'LlB3Oo6rckI', 'Rab Ne Bana Di Jodi', 0, 95],
['Omana Penne', 'TAMIL', '2010', 'A R Rahman', 'CNPtULCngsQ', 'Vinnaithaandi Varuvaayaa', 0, 323],
['Youre the voice', 'HINDI', '2015', 'Sachin - Jigar', 'DcCHScn4gpw', 'ABCD 2', 0, 217],
['Incomplete', 'ENGLISH', '2005', ' Backstreet Boys', 'AiWsQrUot6c', 'Never Gone', 0, 235],
['Kya Mujhe Pyar Hai', 'HINDI', '2006', 'Pritam', '0KIaQr4hqf8', 'Woh Lamhe', 0, 250],
['The Battle', 'ENGLISH', '2000', 'Hans Zimmer ', 'I-YYZiJvwpc', 'Gladiator', 518, 590],
['Manasaa', 'TELUGU', '2010', 'A R Rahman', 'Tqx6BtVsEH8', 'Yemaaya Chesave', 0, 248],
['Oy..Oy..', 'TELUGU', '2009', 'Yuvan Shankar Raja', 'dGH-YczynDc', 'Oy'],
['Manmadhudu BGM 2', 'TELUGU', '2002', ' Devi Sri Prasad', 'qzqelZE_Lok', 'Manmadhudu BGMS'],
['Kalaba Kaadhala', 'TAMIL', '2003', 'Harris Jayaraj', 'kWkuQ2UYwMk', 'Kaakha Kaakha', 0, 164],
['Time is on my side', 'ENGLISH', '2012', 'Rolling Stones', '_oSRvcdlgSI', 'Hall of Fame'],
['Maa Perati Jamchettu', 'TELUGU', '1996', 'M. M. Keeravani', '_PLWJF-cOFA', 'Pelli Sandadi', 0, 302],
['Good Time', 'ENGLISH', '2008', 'Owl City & Carly Rae Jepsen', 'cmLSizwDGj4', 'Maybe', '-', 0, 201],
['Vasta Ne Venuka BGM', 'TELUGU', '2004', 'A. R. Rahman', 'N8hz86g6IFw 1', 'Naani', 564, 758],
['Ghajini BGM 7', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 49', 'Ghajini', 1086, 1122],
['Channa Mereya Unplugged', 'HINDI', '2016', 'Pritam', 'Ghxdb7_sa0o', 'Ae Dil Hai Mushkil'],
['Aaoge Jab Tum', 'HINDI', '2007', 'Pritam', 'CNZMIhckaA0', ' Jab We Met', 0, 352],
['Neethane flute bgm', 'TAMIL', '2017', 'A. R. Rahman', 'zJlRUUWozlI', 'Mersal'],
['Party in the USA', 'ENGLISH', '2009', 'Miley Cyrus', 'BlHsvqzHvag', 'Party in the U.S.A.', 0, 201],
['Vaseegara', 'TAMIL', '2001', 'Harris Jayaraj', 'Po2kfw5PMv8', 'Minnale', 0, 295],
['Kannanule', 'TELUGU', '1995', 'A. R. Rahman', '6ViLM0XW-3g', 'Bombay', 0, 350],
['Otha Sollaala', 'TAMIL', '2011', 'GV Prakash', 'FNLrzbv0Vdg', 'Aadukalam', 0, 236],
['Kadhalikkum Aasai', 'TAMIL', '2004', 'Harris Jayaraj', 'w8yE93WuTnQ', 'Chellame', 0, 270],
['Jab Tak Hai Jaan Bgm', 'HINDI', '2011', 'A R Rahman', '4EIo-f3h9JE', 'Jab Tak Hai Jaan'],
['Kailove Chedugudu', 'TELUGU', '2000', 'A. R. Rahman', '0rIXXuwnSFQ', 'Sakhi', 0, 208],
['Vinnaithandi Varuvaya BGM 2', 'TAMIL', '2010', 'A R Rahman', 'UExpfg6vdbU', 'Vinnaithaandi Varuvaayaa'],
['Extreme Ways', 'ENGLISH', '2016', 'Moby', 'ftm1hiXgYsA', 'Jason Bourne', 0, 290],
['Feel my love', 'TELUGU', '2004', 'Devi Sri Prasad', 'vAqNDOeBGZk', 'Arya', 0, 295],
['Ghajini BGM 2', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 2', 'Ghajini', 696, 844],
['Senorita', 'HINDI', '2011', 'Shankar-Ehsaan-Loy', '2Z0Put0teCM', 'Zindagi Na Milegi Dobara', 0, 229],
['4 Minutes', 'ENGLISH', '2016', 'Justin Timberlake & Timbaland & Madonna', 'BgPqNyqYDN4', ' Timbo rnb', 0],
['Athiradi', 'TAMIL', '2007', 'AR RAHMAN', 'p5U4zIIyoD8', 'Sivaji'],
['Nanne Nanne', 'TELUGU', '2004', 'Harris Jayaraj', 'NZ_zARhxsIM', 'Gharshana', 0, 350],
['Samjhawan Love Theme', 'HINDI', '2014', 'Sachin-Jigar', '8oDeQtfd_oQ', 'Humpty Sharma Ki'],
['Akila Akila', 'TAMIL', '2012', 'Harris Jayaraj', 'OBNHK6NOuY0', 'Oru Kal Oru Kannadi', 0, 280],
['Right Now (Na Na Na)', 'ENGLISH', '2008', 'Akon', 'If5KeoEDGoo', 'Right Now (Na Na Na)', 0, 240],
['Befikra', 'HINDI', '2016', 'Meet Bros', 'LN07AbMX_HU', '2016 Top 10 Dance Songs', 0, 350],
['Shape of you', 'ENGLISH', '2017', 'Ed Sheeran', 'VJ2rlci9PE0', 'Shape of You (Stormzy Remix)', 0, 230],
['Lehenga', 'HINDI', '2007', 'Vishal-Shekhar', 'F2Q5FC7Xouc', 'Om Shanti Om', 3, 208],
['Starboy', 'ENGLISH', '2016', 'The Weeknd', 'dMMUH_ZpbB0', 'Starboy', 0, 224],
['Ghajini BGM 6', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 46', 'Ghajini', 1036, 1085],
['Chadhti Lehrein Laang Na Paye', 'HINDI', '2010', ' Amit Trivedi', '6RLRUNoTHRY', 'Udaan'],
['Kun Faya Kun', 'HINDI', '2011', 'A.R Rahman', '0RDI9CMilhk', ' Rockstar', 0, 467],
['Vayyarala Jabilli BGM 2', 'TELUGU', '2011', 'Mani Sharma', 'Yg-8_MfqDQc 2', 'Teenmaar', 89, 122],
['Cheliya Cheliya', 'TELUGU', '2001', 'Mani Sharma', '_ruDPvH9_Eg', 'Kushi', 16, 355],
['Home', 'ENGLISH', '2012', 'Phillip Phillips', 'HoRkntoHkIE', 'The World from the Side of the Moon', 2, 211],
['En Kadhal Solla', 'TAMIL', '2010', 'Yuvan Shankar Raja', '61U0e-i4jSw', 'Paiya', 0, 290],
['Mission: Impossible Theme', 'ENGLISH', '-', 'Michael Giaccino', 'E5HGZZMOX3U', '-'],
['The Plan', 'ENGLISH', '-', 'Travis Scott', 'iYKXdt0LRs8', '-', 0, 184],
['Devuda Devuda', 'TELUGU', '2005', 'Vidyasagar', 'WHK-zWXr7Vg', 'Chandramukhi', 0, 306],
['Aa Jao Meri Tamanna', 'HINDI', '2009', 'Pritam', '-enLn27qpSg', 'Ajab Prem Ki Ghazab Kahani', 0, 240],
['Neevalle Neevalle', 'TELUGU', '2007', 'Harris Jayaraj', '5xUTQD5rUB0', ' Neevalle Neevalle', 0, 280],
['Nenjai Poopol', 'TAMIL', '2001', 'Harris Jayaraj', 'htse-TJ4LNA', 'Minnale', 7, 63],
['Chirugali Veechane', 'TELUGU', '2003', 'Ilaiyaraaja', '9gC9ysiI2vA', 'Siva Putrudu', 10, 344],
['Uff Teri Adaa', 'HINDI', '2010', 'Shan-Ehsaan-Loy', '-rSOvmWRG4Y', 'Karthik Calling Karthik'],
['Hallelujah ', 'ENGLISH', '2012', 'Leonard Cohen', 'ttEMYvpoR-k', ''],
['Anisuthide', 'TELUGU', '2018', 'Vishal Chandrasekhar', '89e1d_xkASE', 'Padi Padi Leche Manasu'],
['Summer Of ’69', 'ENGLISH', '1988', 'Bryan Adams', 'HtipPCt317Y', 'Reckless', 10],
['NEE KALLALONA', 'TELUGU', '2014', 'Devi Sri Prasad', 'ehLAPMriTts', 'nenokkadine', 0, 126],
['Soniya', 'TAMIL', '1997', 'AR RAHMAN', 'sHbiwr5Ue6Y', 'Ratchagan'],
['Feels', 'ENGLISH', '2005', 'Bon Jovi', 'qXW3CHpv9fc', 'Oral Fixation Vol. 2'],
['Children', 'ENGLISH', '1996', 'Robert Miles', 'LafSIzwdo-s', 'Dreamland'],
['Poosindi', 'TELUGU', '1991', 'M.M.Keeravani', '2v2JLCgru7U', 'Seetharamaiah Gari Manavaralu'],
['Kottaga Rekkalochena  | Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'VdL8lEXCEmo', 'Songs from the Big Chair'],
['O Papa Laali', 'TELUGU', '1989', 'IlayaRaaja', '3rYoe4HglV0', 'Geethanjali', 0, 265],
['Megham Karukatha', 'TAMIL', '2005', 'Harris Jayaraj', 'zYc83YbeU-U', 'Ghajini'],
['Vinnaithandi Varuvaya BGM 4', 'TAMIL', '2010', 'A R Rahman', '_9Q9E2s71S4', 'Vinnaithaandi Varuvaayaa'],
['Engeyum Kadhal', 'TAMIL', '2011', 'Harris Jayaraj ', 'Ue0EpN0q_Kc', 'Engeyum Kadhal', 0, 330],
['Remo - BGM 8', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 8', 'Remo', 584, 612],
['Idhe Paata Prati Chota', 'TELUGU', '1973', 'Satyam', 'x3KMwZwPLDM', 'Puttinillu Mettinillu'],
['Enna Solla', 'TAMIL', '2015', 'Anirudh ', '5iR5V9sHEtQ', 'Thanga Magan', 0, 214],
['Tujhe Kitna Chahne', 'HINDI', '2019', 'Mithoon', 'Hq5OTJdBKQU', 'Kabir Singh'],
['Yele Yele', 'TELUGU', '2009', 'Mickey J. Meyer', 'KBRrbNpHcUw', 'Ganesh', 0, 280],
['See you again Instrumental', 'ENGLISH', '2010', 'Charlie Puth', '4LXLwtqO4zY', 'See You Again'],
['Ghajini BGM 9', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 34', 'Ghajini', 1226, 1265],
['Mountains', 'ENGLISH', '2014', 'Hans Zimmer ', 'lM5IxG1qhl0', 'Interstellar', 0, 548],
['Sahasame Cheyyara', 'TELUGU', '1996', 'Sandeep Chawtha', 'Dek8t7CpTQc', 'Chandralekha'],
['Gagananiki', 'TELUGU', '1998', 'Deva', 'vcCvLsiZzrc', 'Tholiprema', 0, 352],
['Ko Kokodi', 'TELUGU', '2007', 'Mani Sharma', 'x4ELDtNt3K4', 'Jai Chiranjeeva'],
['Khabar Nahi', 'HINDI', '2008', 'Vishal-Shekhar', '4HzgdWnfJXk', 'Dostana', 0, 255],
['Vinnaithandi Varuvaya BGM 3', 'TAMIL', '2010', 'A R Rahman', 'vNk7jcJMIhQ', 'Vinnaithaandi Varuvaayaa'],
['A Real Hero', 'ENGLISH', '1985', 'Tears for Fears', 'BHgYtKkSEDA', 'Songs from the Big Chair'],
['Drive By', 'ENGLISH', '2012', 'Train', 'oxqnFJ3lp5k', 'California 37', 10, 204],
['Naan Nee', 'TAMIL', '2014', 'Santhosh Narayanan', 'VMYWqrlB7Gc', 'Madras', 0, 252],
['Tu Chahiye', 'HINDI', '2015', 'Pritam', 'WTLLym2wzIM', 'Bajrangi Bhaijaan '],
["Livin' La Vida Loca", 'HINDI', '1999', 'Ricky Martin', 'p47fEXGabaY', 'Ricky Martin'],
['Aura Ammakuchella', 'TELUGU', '1992', 'M. M. Keeravani', 'gdxkviJ6IJY', 'Aapadbandhavudu'],
['Pedave Palikina BGM', 'TELUGU', '2004', 'A. R. Rahman', 'N8hz86g6IFw 2', 'Naani', 0, 300],
['Tujh Mein Rab Flute', 'HINDI', '2008', 'Salim-Sulaiman', 'CB0o2cGq8Ow', 'Rab Ne Bana Di Jodi', 0],
['Manmadhan BGM 4', 'TAMIL', '2005', 'Harris Jayaraj', 'KYYHOkku5FU 4', 'Ghajini', 621, 663],
['Gerua/Kabira', 'HINDI', '2016', 'Bryden-Parth', 'pns3P7DNad8', 'Dilwale', 7, 396],
['Dream on', 'ENGLISH', '1973', 'Aerosmith', '89dGC8de0CA', 'Aerosmith', 0, 265],
['Orasaadha', 'TAMIL', '2018', 'Vivek–Mervin', '93Y_eCwdR5k', 'The Tamil EDM Mixtape', 16, 248],
['Samjhawan', 'HINDI', '2014', 'Sachin-Jigar', 'H2f7MZaw3Yo', 'Humpty Sharma Ki', 0, 262],
['Ghajini BGM 5', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 14', 'Ghajini', 977, 1035],
['Rang De Basanti', 'TAMIL', '2006', 'AR RAHMAN', 'Exrs85DTD0A', 'Rang De Basanti', 82, 442],
['Girls like you', 'TAMIL', '1999', 'A R Rahman', '0lZ2AFZR6IU', 'Padayappa'],
['Ankhon Mein Teri  Flute Cover', 'ENGLISH', '1985', 'Tears for Fears', 'FzSuVryu-3s', '-'],
['Moonu Ram writes his suicide note BGM ', 'TAMIL', '2011', 'Anirudh', 'jp4foNfPzx8', 'Moonu'],
['Neelo Valapu', 'TELUGU', '2010', 'A. R. Rahman', 'DevODhVK9xY', 'Robo', 0, 338],
['Mellaga', 'TELUGU', '2007', 'Devi Sri Prasad', 's3MgIV0F_c0', 'Shankardada Zindabad'],
['Manohara', 'TELUGU', '2001', 'Harris Jayaraj', 'DPDFV16QfJA', 'Cheli', 15],
['Stairway To Heaven', ' ENGLISH', '1971', ' Led Zeppelin', 'QkF3oxziUI4', 'The Song Remains Same', 0, 480],
['Wavin Flag', 'HINDI', '2006', 'Shankar-Ehsaan-Loy', 'usjS6de7eaI', 'Kabhi Alvida Naa Kehna'],
['Kadhalar Dhinam BGM', 'TAMIL', '1999', 'A. R. Rahman', 'APbEIYTQmoQ', 'Kadhalar Dhinam'],
['Hookah Bar', 'TAMIL', '2005', 'Harris Jayaraj', '5lZ5qAXrtUc', 'Ghajini'],
['O Humdum Suniyo Re', 'TELUGU', '2016', 'A R Rahman', '_9geEbZIAJM', 'Saathiya', 6],
['Padi Padi Leche Manasu', 'TELUGU', '2018', 'Vishal Chandrasekhar', 'uNLX3CffxTE', 'Padi Padi Leche Manasu', 0, 198],
['Naan Un Azhaginile', 'TAMIL', '2015', 'A R Rahman', 'Y8D1ARdJCOs', '24'],
['Mera Mann Kehne Laga', 'HINDI', '2013', 'Falak Shabir', '8kxufj_snhI', 'Nautanki Saala!'],
['Maddy Maddy', 'TAMIL', '2001', 'Harris Jayaraj', 'fo-UM1VLRDM', 'Minnale', 0, 72],
['Soul Sister', 'ENGLISH', '2009', 'Train', 'JloUN9j9cJ8', 'Hey, Soul Sister: Remixes EP', 0, 215],
['Anuragam', 'TELUGU', '2001', 'M. M. Keeravani', 'rIdScfz6mCQ', 'Bava Nachadu', 0, 319],
['Girl Friend', 'TAMIL', '2003', 'A R Rahman', 'PS2wqyUh_OE', 'Boys', 0, 272],
['I Found You', 'TAMIL', '2008', 'A. R. Rahman', 'iN_HjYFDV6Q', 'Sakkarakatti'],
['La Isla Bonita Alizée', 'TAMIL', '2008', 'A. R. Rahman', 'xq-aTe77bkA', 'Sakkarakatti'],
['Woh Lamhey', 'HINDI', '2007', 'JAL', '9nemfDx-EVM', 'AADAT', 0, 323],
['Faded guitar cover 2', 'ENGLISH', '2015', 'Alan Walker', 'S9TWlT_o_ME', '-'],
['High Hopes', 'ENGLISH', '1979', 'Pink Floyd', 'lku1fP-LszI', 'The Wall', 0, 450],
['Enduko Madi', 'TELUGU', '2009', 'Achu & Dharan', '9sdzCtElP-8', 'Neenu Meeku Thelusa', 0, 260],
['The Godfather', 'HINDI', '2017', 'Pritam', 'Flb01Ni3p3M', 'Jab Harry Met Sejal'],
['Mustafa', 'TAMIL', '1996', 'A.R Rahman', 'tfZLxLlCgUk', 'Kadhal Desam'],
['JAB TAK', 'ENGLISH', '-', 'Billy Joel', '2Ejac3hB184', '-'],
['Everybody Wants To Acoustic', 'ENGLISH', '1985', '-', '0ZII3Cu_Uo4', '-'],
['Shayad English cover', 'ENGLISH', '2020', 'Pritam - Emma Heesters', 'xtpIfc8xtOo', 'Love Aaj Kal 2020', 0, 214],
['Ladio', 'TAMIL', '2017', 'AR Rahman', '77lbHTGsQHQ', 'I'],
['Yekommakakomma', 'TELUGU', '1999', 'Mani Sharma', '9eJgdZsUw8U', 'Seenu', 5, 290],
['Rab Ne Bana Di Jodi', 'HINDI', '2008', 'Salim-Sulaiman', 'RMkNYJALZIM', 'Rab Ne Bana Di Jodi', 0, 184],
['Adiyae Kolluthey', 'TAMIL', '2008', 'Harris Jayaraj', 'oNTOpHEpGiA', 'Vaaranam Aayiram'],
['Ayyappa', 'TAMIL', '2013', 'AR Rahman', '8B0AC_TTI6o', '-'],
['Love Elephantla ', 'TELUGU', '2005', 'Harris Jayaraj', 'zkqJ41nQk9I', 'Aparichithudu', 0, 340],
['Nenjukkul Peidhidum', 'TAMIL', '2008', 'Harris Jayaraj', 'FzLpP8VBC6E', 'Vaaranam Aayiram', 0, 337],
['Jeans BGM 6', 'TAMIL', '1998', 'AR RAHMAN', 'FucecTj3_VM', 'Jeans', 0, 64],
['Naan Un Azhaginile BGM', 'TAMIL', '2015', 'A R Rahman', 'k7UNnJYB8Ho 2', '24', 0, 30],
['Tera Yar hu main', 'HINDI', '2007', 'Pritam', 'kH__u3TDCdk', 'Awarapan'],
['VIP BGM 3', 'TAMIL', '2014', 'Anirudh ', '5J6RWxPNOiE', 'Velai Illa Pattadhaari', 930, 1030],
['Enna Vilai Azhake', 'TAMIL', '1999', 'A.R Rahman', 'c2PGWrDh3zU', 'Kadhalar Dhinam', 0, 347],
['Cant Help Falling In Love (Elvis Cover)', 'ENGLISH', '1985', 'Tears for Fears', '6ThQkrXHdh4', '-'],
['Maangalyam Tantunanena', 'TAMIL', '2000', 'A. R. Rahman', 'LTBA_Z3wDr8', 'Alaipayuthe'],
['Ratchagan Theme Music', 'TAMIL', '1997', 'AR RAHMAN', 'UEh2ZUfEazA', 'Ratchagan', 2, 220],
['Maate mantramu', 'TELUGU', '1981', 'IlayaRaaja', 'pxD69QG9GAA', 'Seethakoka chiluka'],
['Lamha Lamha', 'HINDI', '2006', 'Pritam', 'nk39LHhiSM0', 'Gangster'],
["Jessy's Land", 'TAMIL', '2010', 'A R Rahman', 'p8-n77H6E2U', 'Vinnaithaandi Varuvaayaa'],
['Dildara', 'HINDI', '2011', 'Vishal-Shekhar', 'gQ5qVtoLMk4', 'Ra.One', 0, 248],
['Kangal Irandal', 'TAMIL', '2008', 'James Vasanthan', '3qj7o283j1g', 'Subramaniapuram', 0, 322],
["Wavin' Flag", 'ENGLISH', '2009', "K'naan (Feat. Will.i.am, David Guetta) ", 'h9eV9t9EPic', 'Troubadour'],
['Satyameva Jayathe', ' ENGLISH', '2011', ' SuperHeavy', '11HL8ZkfAZI', 'SuperHeavy'],
['En Mannavva', 'TAMIL', '2014', 'AR RAHMAN', 'tDcLTOSpHyI', 'Lingaa', 3, 262],
['Prema Ane', 'TELUGU', '1999', 'A. R. Rahman', 'HpHx5oPwUT4', 'Premikula Roju', 2, 402],
['Ee Manase Se Se', 'TELUGU', '1998', 'Deva', '6GlHG-FoIOE', 'Tholiprema', 0, 263],
['A New Day Has Come (Radio Remix)', ' ENGLISH', '2000', ' Celine Dion', '8fmCwPLi954', '2000 Collection', 0, 257],
['Vaale Vaale', 'TELUGU', '2002', 'Harris Jayaraj', 'g9-S8-8Artk', 'Vaasu', 4, 279],
['7G Brundavan Colony BGM 3', 'TELUGU', '2007', 'Yuvan Shankar Raja', 'i7aSm_lG4yA 3', '7G Brundavan Colony', 86, 143],
['Unnattundi Gundey BGM 1', 'TELUGU', '2017', 'Gopi Sunder', 'AuLFjwS2sIU', 'Ninnu Kori', 0, 46],
['Everything I Need', 'ENGLISH', '2018', 'Skylar Grey', '9thM5gLs2tg', 'Aquaman', 0, 188],
['I m the one', 'ENGLISH', '2012', 'Imagine Dragons', '158plNHX4vw', 'Continued Silence EP'],
['Faded', 'ENGLISH', '2015', 'Alan Walker', '60ItHLz5WEA', '-'],
['Tung Tung Baje', 'HINDI', '2015', '\tSneha Khanwalkar', 'u2VhHGAtr4g', 'Singh Is Bliing'],
['Nuvve Nuvve', 'TELUGU', '2015', 'S. S. Thaman', 'B-mW-XIYHGk', 'Kick 2'],
['7 years ', 'ENGLISH', '2005', 'Bon Jovi', 'Q0bnAmfGQC8', 'Oral Fixation Vol. 2'],
['Forever young ', 'ENGLISH', '2005', 'Youth Group', 'Nzdt9vBvSDE', 'The O.C.'],
['Edo Oka Raagam', 'TELUGU', '1999', 'S.A.Raj Kumar', 'ew_PdDxS004', 'Raja', 0, 296],
['Say Na Say Na', 'HINDI', '2005', 'Vishal-Shekhar', 'sbBgxwf6pJs', 'Bluff Master', 0, 194],
['Jimikki Kammal', 'MALAYALAM', '2017', 'Shaan Rahman', 'IvkVJt4lhGA', 'Velipadinte Pusthakam', 0, 195],
['Mahiya', 'HINDI', '2019', 'Beethoven', 'dhTUGbVgogY', 'Kabir Singh'],
['Stiches', 'ENGLISH', '2015', 'Shawn Mendes', 'M_yYWMOKjvE', 'Stitches (Seeb Remix)'],
['Ye Chilipi', 'TELUGU', '2004', 'Harris Jayaraj', '5dQHjDuK-0w', 'Gharshana', 0, 314],
['Priyatama', 'TELUGU', '1990', 'IlayaRaaja', 'HK0xFMCkwrM', 'Jagadeka Veerudu Atiloka Sundari', 0, 355],
['Kunidu kunidu', 'TELUGU', '2018', 'Vishal Chandrasekhar', 'T0eSK2YGplc', 'Padi Padi Leche Manasu'],
['S.T.A.Y.', 'ENGLISH', '2014', 'Hans Zimmer ', 'fOvFnga339U', 'Interstellar'],
['Tholiprema BGM', 'TELUGU', '1998', 'Deva', 'Imi6-35mLw8', 'Tholiprema'],
['Paint it Black', 'ENGLISH', '-', 'Billy Joel', 'EBUFtoC2oj4', '-'],
['Tu hi meri shab hai', 'HINDI', '2007', 'Pritam', '1lkYqttkT3c', ' Jab We Met'],
['Pookkale Satru', 'TAMIL', '2017', 'AR Rahman', 'OCsrcPizVjA', 'I'],
['MATE RANI CHINNADANI', 'TELUGU', '1989', 'IlayaRaaja', '_Q7eJ9b2aSY', 'O Papa Lali', 0, 246],
['Feel my love BGM', 'TELUGU', '2004', 'Devi Sri Prasad', '7lgOTuPLDtc', 'Arya', 122, 162],
['Ade Neevu', 'TELUGU', '1988', 'IlayaRaaja', '_k3VZ_INFf0', 'Abhinandana', 16, 286],
['Kottaga', 'TELUGU', '1988', 'IlayaRaaja', '7sbrHwvGids', 'Swarna Kamalam ', 16, 283],
['Wake Up Sid! (Club Mix)', 'HINDI', '2009', 'Shankar-Ehsaan-Loy', 'PsfNVND30I8', 'Wake Up Sid'],
['Aankhon mein teri', 'HINDI', '2007', 'Vishal-Shekhar', 'bdS6OoH1W2A', 'Om Shanti Om'],
['Magnificent Unknown', 'HINDI', '2017', 'Pritam', 'a9f094kZNnQ', 'Jab Harry Met Sejal'],
['Something That I Already Know', ' ENGLISH', '2007', ' Backstreet Boys', 'm291VyX5dTQ', 'Unbreakable', 4, 212],
['Remo - BGM 11', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 11', 'Remo', 910, 972],
['Enno ratrulostayi gani cover', 'ENGLISH', '1985', 'Tears for Fears', 'o08ntJh3Z2E', 'Songs from the Big Chair'],
['Katiya Karoon', 'HINDI', '2011', 'A R Rahman', 'IlVjro3FlXM', 'Rockstar', 0, 234],
['Minnale BGM 4', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 4', 'Minnale', 1596, 1696],
['Sky full of stars', 'ENGLISH', '2014', 'Coldplay', 'ot_snHDVTUU', 'Ghost Stories', 0, 266],
['Padhe Padhe', 'TELUGU', '2019', 'Anirudh', 'VVxPv5yA4w8', 'Jersey', 0, 201],
['Somebody That I Used To Know - X Factor', 'ENGLISH', '2012', 'Gotye', 'WWdblzKflc4', 'Making Mirrors', 0, 134],
['Ghajini BGM 10', 'TAMIL', '2005', 'Harris Jayaraj', 'faOR9KKzqLs 40', 'Ghajini', 1563, 1584],
['Kallu Moosi', 'TELUGU', '2009', 'Harris Jayaraj', '7_RsU4ECRZo', 'Veedokkade', 6],
['Vaaranam Aayiram bgm', 'TAMIL', '2008', 'Harris Jayaraj', 'BIKEV95piw4', 'Vaaranam Aayiram', 298, 329],
['Matargasthi flute collection', 'HINDI', '2015', 'A R Rahman', '53919sdD4yM', 'Tamasha', 3, 222],
['Thangamey BGM 2', 'TAMIL', '2015', 'Anirudh', 'qqrsa2Y3Lyw', 'Naanum Rowdy Dhaan'],
['Boom Boom Telugu', 'TELUGU', '2003', 'A R Rahman', 'zeoWaI8WsFk', 'Boys', 0, 300],
['Padi padi leche manasu BGM 2', 'TELUGU', '2018', 'Vishal Chandrasekhar', '95LQ35q9hGY 2', 'Padi Padi Leche Manasu', 767, 792],
['Peela Peela', 'TAMIL', '2018', 'Anirudh', 'oBiEYXDkJnc', 'Thaanaa Serndha Koottam'],
['Askava', 'TELUGU', '2003', 'A R Rahman', 'wRPMfgNPl-Q', 'Nee Manasu Naaku Telusu', 0, 355],
['Dil Diyyan Cover cover', 'HINDI', '2019', 'Mithoon', 'qnRuI7hDJQ8', 'Kabir Singh'],
['Khwaja Mere Khwaja', 'HINDI', '2008', 'A. R. Rahman', 'rf9_x9fT0rY', 'Jodha Akabar', 0, 414],
['Without you', 'ENGLISH', '2011', 'David Guetta - Usher', 'jUe8uoKdHao', '-', 0, 208],
['Chukkallara Choopullara', 'TELUGU', '1992', 'M. M. Keeravani', 'htGOXmZtmXU', 'Aapadbandhavudu'],
['Galliyan Returns', 'TELUGU', '1996', 'A R Rahman', 'oPS-ayK43zI', 'Prema Desam'],
['Faded guitar cover', 'ENGLISH', '2015', 'Alan Walker', 'gnGA1o43UOI', '-'],
['Nee Yedhalo Naku', 'TELUGU', '2010', 'Yuvan Shankar Raja', 'nZZ3U_ACUAc', 'awaara'],
['Kabira Unplugged', 'HINDI', '2016', 'Pritam', 'dYOFF6vZtQw', 'Ae Dil Hai Mushkil'],
['Preyasi Kaavu', 'TELUGU', '2005', 'Mahesh Shankar', 'sG8TKttaDHg', 'Vennela'],
['Adiga Adiga Guitar Bit', 'TELUGU', '2017', 'Gopi Sundar', 'dV3C-bZN3o8', 'Ninnu Kori', 14, 86],
['See You Again - live', 'ENGLISH', '2010', 'Charlie Puth', '37Vh_etC18w', 'See You Again', 30, 260],
['Drink Up Me Hearties Yo Ho', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'RUNRoXbaflM', 'Pirates Of The Caribbean'],
['Monna Kanipinchavu', 'TELUGU', '2008', 'Harris Jayaraj', 'ZlskvfYmj88', 'Surya S/o Krishnan', 0, 322],
['Main Agar Kahoon', 'HINDI', '2007', 'Vishal-Shekhar', 'DAYszemgPxc', 'Om Shanti Om', 0, 306],
['Munbe vaa BGM - 5', 'TAMIL', '2020', 'A. R. Rahman', '5OunSxegSOA 6', 'Sillunu Oru Kaadhal', 128, 220],
['Sydney Nagaram', 'TELUGU', '2010', 'Harris Jayaraj', 'KKzUtsjAl74', 'Orange', 16, 349],
['Minnale BGM 3', 'TAMIL', '2001', 'Harris Jayaraj', 'spOpmgPNvr0', 'Minnale'],
['Bailamos', 'HINDI', '1999', ' Enrique Iglesias', '5sye_VxmNZA', 'Cosas del Amor', 0, 212],
['Pirates Of The Caribbean Main Theme', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'K3pU67zxPOM', 'Pirates Of The Caribbean', 0, 270],
['Moonu BGM 3', 'TAMIL', '2011', 'Anirudh', 'XY5KTVDi99o', 'Moonu'],
['Apologize', 'ENGLISH', '2006', 'OneRepublic', '5ub14kkXKKc', ' Apologize', 19, 204],
['Minnale BGM 1', 'TAMIL', '2001', 'Harris Jayaraj', 'Q3lGj-zH0Wc 1', 'Minnale', 0, 112],
['Nenunnanani', 'TELUGU', '2004', 'M.M.Keeravani', 'SbqFZHzwDoI', 'Nenunnanu', 16, 226],
['Agni Skalana', 'TAMIL', '1999', 'A R Rahman', 'NTfhqNcqo6A', 'Padayappa'],
['Baarish', 'HINDI', '2008', 'Tanishk Bagchi', 'BNfAf4To73c', 'Half Girlfriend', 0, 272],
['Chill Bro', 'TAMIL', '2020', 'Vivek–Mervin', 'pHxz8l1iWU4', 'Pattas'],
['Dil Diyan Gallan', 'HINDI', '2017', 'Vishal-Shekhar', 'mevO4I0f5lg', 'Tiger Zinda Hai', 0, 249],
['Andhanikey', 'TELUGU', '2001', 'Mani Sharma', 'XRxhz4qyM2k', 'Murari', 0, 321],
['Pehli Baar Mohabbat', 'HINDI', '2009', 'Vishal Bhardwaj', 'itc-rHSsDj4', 'Kaminey'],
['Em Beihold - Numb Little Bug ', 'TAMIL', '2017', 'AR Rahman', 'qCaparx0f3c', 'I'],
['Hairat', 'HINDI', '2010', 'Vishal-Shekhar', 'wqTQNs9sO6M', 'Anjaana Anjaani'],
['Tabaah Ho Gaye', 'HINDI', '2019', 'Pritam', '2eJlokx5HEs', 'Kalank'],
['La Isla Bonita', 'TAMIL', '2008', 'A. R. Rahman', 'ZmzjH6OjjIA', 'Sakkarakatti'],
['Cold Water', 'ENGLISH', '2016', 'Major Lazer - Justin Bieber ', 'a59gmGkq_pw', ' Cold Water', 0, 182],
['Dynamite', 'ENGLISH', '2009', 'Taio Cruz', 'Jq8rVrcbUXY', 'Rokstarr ', 0, 200],
['Yun Hi Chala Chal', 'HINDI', '2004', 'A. R. Rahman', 'vTBbi3qIC0s', 'Swades', 0, 445],
['Vayyarala Jabilli BGMPirindhaai 1', 'TELUGU', '2011', 'Mani Sharma', 'Yg-8_MfqDQc 1', 'Teenmaar', 0, 58],
['Remo - BGM 5', 'TAMIL', '2016', 'Anirudh', 'Bf67mfzqb50 5', 'Remo', 308, 366],
['Just a dream', 'ENGLISH', '2010', 'Nelly', '64ObKHp33i4', '5.0', 0, 235],
['MAIN HOON NA', 'HINDI', '2004', 'Anu Malik', 'MzhsLzOTZNA', 'MAIN HOON NA', 0, 357],
['Anbil Avan', 'TAMIL', '2010', 'A R Rahman', 'bXa-wbiXiOw', 'Vinnaithaandi Varuvaayaa'],
['Kadhile Kalamey', 'TELUGU', '1999', 'AR RAHMAN', 'cqAWqqxTAuk', 'Jodi'],
['Manasa Manasa', 'TELUGU', '2021', 'Gopi Sunder', 'R6Fej0Eoy1o', 'Most Eligible Bachelor'],
['Fa la la', 'ENGLISH', '2011', 'Justin Bieber ft. Boyz II Men', '6-YdDqRzLsM', 'Under the Mistletoe'],
['Desi Girl', 'HINDI', '2008', 'Vishal-Shekhar', 'oxO0C0Fj7bc', 'Dostana', 0, 296],
['Tere naam', 'HINDI', '2006', 'Shankar-Ehsaan-Loy', '2d6AH2bsQEM', 'Kabhi Alvida Naa Kehna'],
['Gulabi Kallu', 'TELUGU', '2014', 'Yuvan Shankar Raja', 'I4oP0mlkAs8', 'Govindudu Andarivadele', 0, 260],
['Cheliya Celiya', 'TELUGU', '2004', 'Harris Jayaraj', 'A7ipnxIyyno', 'Gharshana', 0, 319],
['Where Them Girls At', 'ENGLISH', '2011', 'Nicki Minaj, Flo Rida - David Guetta', 'p4kVWCSzfK4', 'Nothing but the Beat', 46, 222],
['Thunder', 'ENGLISH', '2012', 'Imagine Dragons', 'GtEvysh1654', 'Continued Silence EP'],
['Stressed out', 'ENGLISH', '2012', 'Imagine Dragons', 'f1tDFtMjwAE', 'Continued Silence EP'],
['A New Day Has Come', ' ENGLISH', '2000', ' Celine Dion', 'ZcEqCsDflY4', '2000 Collection', 0, 339],
['Ennenno Pathalu', 'HINDI', '2007', 'Pritam', 'IFUy2t4aqN0', 'Naqaab'],
['So Good', 'ENGLISH', '2012', ' B.o.B', 'IoBP24I2lwA', 'Strange Clouds', 0, 210],
['Starboy (Live From The Victoria’s Secret)', 'ENGLISH', '2016', 'The Weeknd', 'vuT_bXzhqhY', 'Starboy'],
['Foxey Lady', 'ENGLISH', '1968', 'Jimi Hendrix', '_PVjcIO4MT4', 'The Jimi Hendrix Experience', 0, 264],
['Okkadu BGM 2', 'TELUGU', '2003', 'Mani Sharma', 'OGCeF7V9q2A', 'Okkadu'],
['Kitaben Bahut Si', 'HINDI', '1993', 'Anu Malik', '0vWhZssFtVs', 'Baazigar', 0, 384],
['Ye Tune Kya Kiya', 'HINDI', '2013', 'Pritam', 'YwMxSbwh_H4', 'Once Upon A Time In Mumbai Dobaara!', 40, 300],
['Streets of Philadelphia', 'TAMIL', '2008', 'A. R. Rahman', 'tKdk97y2Wjg', 'Sakkarakatti'],
['Samajavaragamana', 'TELUGU', '2019', 'S. Thaman', 'peLhDdjKUWU', 'Ala Vaikunthapurramloo', 0, 214],
['Nuvvu Nuvvu', 'TELUGU', '2002', ' Devi Sri Prasad', 'TEk_aKCBgis', 'Kadgam', 16, 348],
['Jeans BGM 2', 'TAMIL', '1998', 'AR RAHMAN', 'FBhdm3m7VZA', 'Jeans', 30, 115],
['Annul maelae', 'TAMIL', '2008', 'Harris Jayaraj', 'eG5Zx7qq2C4', 'Vaaranam Aayiram', 0, 307],
['Now we are free guitar cover', 'HINDI', '2009', 'Pritam', '1-tOy82H364', 'Billu', 1, 180],
['Bailando', 'ENGLISH', '2013', 'Enrique Iglesias', 'b8I-7Wk_Vbc', 'Bailando (Remixes)', 0, 250],
['Pithamangan BGM 2', 'HINDI', '2020', 'Pritam', 'KUi_eW5UL1U 2', 'Love Aaj Kal 2020', 1269, 1316],
['Telusuna', 'TELUGU', '2002', 'Devi Sri Prasad', '46BQJX5v5xk', 'Sontham', 0, 275],
['Chukkala Chunni', 'TELUGU', '2018', 'Chaitan Bharadwaj', '1tL0rsKvZFE', ''],
['Minnale BGM 4', 'TAMIL', '2001', 'Harris Jayaraj', 'bYLQZOqKGL8', 'Minnale'],
['The Nights', ' ENGLISH', '2014', 'Avicii', 'UtF6Jej8yb4', ' FIFA 15 Soundtrack'],
['I Took A Pill In Ibiza', 'ENGLISH', '2012', 'Imagine Dragons', 'u3VFzuUiTGw', 'Continued Silence EP'],
['Tera Banjaunga Reprise', 'TAMIL', '2015', 'Harris Jayaraj', 'sIlLdt0t9OA', 'Anegan'],
['Perfect', 'ENGLISH', '2017', 'Ed Sheeran', 'iKzRIweSBLA', 'Divide', 0, 258],
['Yeh Honsla', 'HINDI', '2006', 'Salim–Sulaiman', '3TndEMf6zws', 'Dor', 0, 270],
['Maahi Ve', 'HINDI', '2003', 'Shankar-Ehsaan-Loy', 'VudHUqt17us', 'Kal Ho Naa Ho'],
['Woh Lamhey DJ Mix', 'HINDI', '2005', 'JAL', '3aPKCzgvh0Y', 'Zeher'],
['Nothing Else Matters Cover', 'ENGLISH', '1992', 'Metallica-Thaikkudam Bridge', 'PqEr-03xbkU', 'Metallica', 0, 381],
['Merupu Chukke', 'TELUGU', '2006', 'Harris Jayaraj ', '4c7mfcKUb2Y', 'Raghavan', 0, 285],
['Maa Tujhe Salam', 'TAMIL', '1997', 'A. R. Rahman', 'e60xy-jt_zI', 'Vande Mataram', 0, 367],
['Murari BGM 1', 'TELUGU', '2001', 'Mani Sharma', 'SXFOT6b9eeE', 'Murari'],
['New Divide', 'TAMIL', '2008', 'A. R. Rahman', 'Sk2x_k1GWt8', 'Sakkarakatti'],
['Ciciliya', 'TELUGU', '2017', 'Harris Jayaraj', '8204mQVmiBo', 'Spyder'],
['Iron Man', 'TAMIL', '2008', 'A. R. Rahman', 'F01UTYg79KY', 'Sakkarakatti'],
['What A Wonderful World', 'HINDI', '2017', 'Pritam', 'rBrd_3VMC3c', 'Jab Harry Met Sejal'],
['Evaree Ammayee', 'TELUGU', '2013', 'Yuvan Shankar Raja', 'Y5rwnARsAZY', 'Nene Ambani', 0, 300],
['Something Just Like This (Cover)', 'ENGLISH', '2017', 'Chainsmokers', '2LBBaD1aQuw', 'Something Just Like This'],
['Munbe Vaa-Masala Coffee', 'TAMIL', '2006', 'A. R. Rahman', 'X9RKX4IzNVw', 'Sillunu Oru Kaadhal', 0, 207],
['Mere Naseeb Mein', 'HINDI', '2006', '-', 'bnjYVnQ_cPA', '-'],
['Subhalekha Rasukunna', 'TELUGU', '1990', 'IlayaRaaja', 'mKzXohdSc4c', 'Kondaveeti Donga', 0, 270],
['Aye Udi Udi Udi', 'HINDI', '2002', 'A. R. Rahman', '3BsFE3WD0kA', 'Saathiya'],
['Yemaindo Yemo Ee Vela', 'TELUGU', '1998', 'Deva', '5M7U2BwKxU8', 'Tholiprema', 0, 280],
['Sun Saathiya', 'HINDI', '2015', 'Sachin - Jigar', '_NWaYjsz3qY', 'ABCD 2', 0, 217],
["Can't Help Falling In Love", 'ENGLISH', '1961', 'Elvis Presley', 'vGJTaP6anOU', 'Blue Hawaii'],
['Kannum Kannum', 'TAMIL', '1993', 'A. R. Rahman', 't37ieNxQ9Q8', 'Thiruda Thiruda', 0, 246],
['Pachani Thota', 'TELUGU', '2013', 'A.R Rahman', 'ACXBk2PoDqU', 'Kadali', 0, 270],
['Singappenney', 'TAMIL', '2019', 'A.R Rahman', 'AOnVRdS6vCs', 'Bigil', 4],
['Who Did That To You', 'TAMIL', '2020', 'A. R. Rahman', '34I2dCO8U8A', 'Sillunu Oru Kaadhal'],
['kadhal Yaanai ', 'TAMIL', '2005', 'Harris Jayaraj', 'A32iRtpUnUw', 'Anniyan', 0, 336],
['Teri Deewani - cover', 'HINDI', '2016', 'Kailash Kher-Thaikkudam Bridge', 'O7wMnyAZOQ8', '-', 450],
['HEYY BABYY', 'HINDI', '2014', 'A.R Rahman', 'qY2q4SIcqiM', ' Highway'],
['Kaalaiyil dhinamum bgm 3', 'TAMIL', '2004', 'A. R. Rahman', 'hrYp0WqsCik', 'New'],
['Yamaho Yama', 'TELUGU', '2007', 'Mani Sharma', '-GG11neCs_8', 'Chirutha', 0, 296],
['Abhi Mujh Mein Kahin Flute', 'HINDI', '2019', 'Ajay-Atul (Aykronix Release)', 'sfv4GEHdKjw', 'Agneepath', 0, 222],
['In The End', 'ENGLISH', '2002', 'Linkin Park', 'fv1pMNdsGWE', 'Reanimation'],
['On Our Way', 'ENGLISH', '2013', 'The Royal Concept', '976Hiy4M6lE', 'Goldrushed', 0, 190],
['Kammani ee', 'TELUGU', '2012', 'IlayaRaaja', 'OidguaDf7Fs', 'yeto vellipoyindi manasu', 0, 134],
['Padayappa Theme', 'TAMIL', '1999', 'A R Rahman', 'JWtTgj9v5Gk', 'Padayappa'],
['Jaane Kyun', 'HINDI', '2008', 'Vishal-Shekhar', '50XjXNsK0Dc', 'Dostana', 0, 273],
['Girlfriend Telugu', 'TELUGU', '2003', 'A R Rahman', 'QVElQZalRDU 3', 'Boys', 0, 302],
['Sonapareeya (BGM - All Versions)', 'TAMIL', '2013', 'AR Rahman', 'olBjYVF4LVs', 'Maryan', 0, 270],
['Chandamama Okati', 'TELUGU', '2005', 'Devi Sri Prasad', 'xH_4JdGp05c', 'Oka Oorilo', 0, 200],
['Konchum Mainakale', 'TAMIL', '2000', 'A R Rahman', 'aH5nH754aVQ', 'Kandukondein Kandukondein', 4],
['Abhi Mujh Mein Kahin', 'HINDI', '2012', 'Ajay-Atul', 'oWKgpB2zpgw', 'Agneepath', 0, 240],
['Paarthen', 'TAMIL', '2017', 'Sean Roldan', 'DZOpwL6t3Lw', 'Power Paandi', 0, 178],
['Petta-BGM-Church Fight', 'TAMIL', '2019', 'Anirudh', 'f3qvrOjOD9M', 'Petta', 0, 212],
['Now We Are Free Orchestra Suite', 'ENGLISH', '2000', 'Hans Zimmer ', '0nfo5jbXY-4', 'Gladiator'],
['Shayad 2', 'HINDI', '2020', 'Pritam', 'y1Wv2Wmg4ms', 'Love Aaj Kal 2020'],
['Petta Paraak', 'TAMIL', '2019', 'Anirudh', '1GmIp526v0M', 'Petta'],
['Minnale BGM 7', 'TAMIL', '2001', 'Harris Jayaraj', 'COPf-YMD9Nw 7', 'Minnale', 1958, 2072],
['Pookal Pookum cover', 'TAMIL', '2010', 'GV Prakash- Thaikkudam Bridge', 'rrvZXzOEI-U', 'Madrasapattinam', 0, 250],
['Unnattundi Gundey', 'TELUGU', '2017', 'Gopi Sunder', 'geZ5tlTENw0', 'Ninnu Kori'],
['In Da Club', 'ENGLISH', '2012', 'Leonard Cohen', 'nFLt_lnxdYk', ''],
['Neruppu Da', 'TAMIL', '2016', 'Santhosh Narayanan', 'LHaGDT6Pdbk', 'Kabali', 0, 210],
['Symphony', 'ENGLISH', '2005', 'Bon Jovi', 'PIf9GvWaxQQ', 'Oral Fixation Vol. 2'],
['Time', 'ENGLISH', '1979', 'Pink Floyd', 'lke-uABclNk', 'The Wall'],
['Adiga Adiga', 'TELUGU', '2017', 'Gopi Sundar', '7dZqS3JIQ1I', 'Ninnu Kori', 6, 162],
['Honor Him', 'ENGLISH', '2000', 'Hans Zimmer ', 'Cq7vKWK3FIU', 'Gladiator'],
['Amani Paadave | Flute version', 'ENGLISH', '1985', 'Tears for Fears', 'LYDZ6M23ung', '-'],
['Romeo Attam Pottal ', 'TAMIL', '1996', 'AR/ RAHMAN', 'mbVrnZt2Eu4', 'Mr Romeo'],
['Oscar Sangamam', 'HINDI', '2020', 'Pritam', 'vhzBES7Fnkk', 'Love Aaj Kal 2020', 12],
['Naa Manasuki', 'TELUGU', '2007', 'Yuvan Shankar Raja', '1sbynj51qpI', 'Aadavari Matalaku Ardhalu Veruley', 0, 338],
['Aathadi Aathadi', 'TAMIL', '2015', 'Harris Jayaraj', 'fv56eF3f1sk', 'Anegan', 0, 344],
['Niddura Potunna', 'TELUGU', '2002', 'Koti', 'WJkCZTWLdN0', 'Nuvve Nuvve', 16, 286],
['Ainvayi Ainvayi', 'HINDI', '2010', 'Salim–Sulaiman', 'Ou3C8HcPgHw', 'Band Baaja Baaraat'],
['Tere Bin', 'HINDI', '2007', 'Rabbi Shergill', '1IMzEh0dsxs', '-', 0, 318],
['Munbe Vaa x New York Nagaram | Remix version', 'TAMIL', '2020', 'A. R. Rahman', 'V-4WyDRSAt0', 'Sillunu Oru Kaadhal'],
['Vacchindi Kada Avakasam', 'HINDI', '2002', 'A. R. Rahman', 'UJND4o5awc4', 'Saathiya'],
['Tune Maari Entriyaan', 'HINDI', '2010', 'Pritam', '97k_BD4XkFE', 'Once Upon a Time in Mumbaai'],
["I'm shipping up to Boston", 'ENGLISH', '2005', 'Dropkick Murphys', '1IIkaH2pY50', "The Warrior's Code", 0, 152],
['Subha Hone Na De', 'HINDI', '2011', 'Pritam', '_wMsvhz8YSk', 'Desi Boyz', 0, 382],
['Hotel California', 'ENGLISH', '-', 'Billy Joel', 'SkK2nSPQEVQ', '-'],
['Chiru Chiru Chinukai', 'TELUGU', '2010', 'Yuvan Shankar Raja', 'eVKLR0cM-FA', 'awaara'],
['Kabi Kabi Aditi', 'ENGLISH', '2003  ', 'Klaus Badelt, Hans Zimmer', 'r9eGi0rVxBw', 'Pirates Of The Caribbean'],
['First Class', 'HINDI', '2017', 'Pritam', '0m2MTd5Jgrw', 'Jab Harry Met Sejal'],
['Closer', 'ENGLISH', '2016', 'The Chainsmokers', 'PT2_F-1esPk', '-', 0, 235],
['Saadi Galli Aaja', 'HINDI', '2013', 'Rochak Kohli - Ayushmann Khurrana', 'D2t8buu_7tU', 'Nautanki Saala!', 0, 250],
['Radha', 'HINDI', '2017', 'Pritam', 'loRj95fRX1c', 'Jab Harry Met Sejal'],
['JIMPAK CHIPAK', 'TELUGU', '2016', 'MC MIKE, SUNNY, UNEEK, OM SRIPATHI', '5ZEhYjTV8RQ', '-', 0, 194],
['Masakali', 'HINDI', '2009', 'AR Rahman', 'WjL2vOoYmBM', 'Dilli 6'],
['What Makes You Beautiful', 'ENGLISH', '2011', 'One Direction', 'QJO3ROT-A4E', 'Up All Night', 4, 201],
['Iru Vizhi Unadhu', 'TAMIL', '2001', 'Harris Jayaraj', 'eFXQAIysMZI', 'Minnale', 0, 108],
['Annula Minnala', 'TELUGU', '1992', 'IlayaRaaja', '6_FWLYuFsT8', 'Chanti', 0, 290],
['Andalalo', 'TELUGU', '1990', 'IlayaRaaja', 'TnJKSmX2xXE', 'Jagadeka Veerudu Atiloka Sundari', 0, 299],
['Thelusa Manasa', 'TELUGU', '1995', 'M.M.Keeravani', 'AliD9PCsymg', 'Criminal'],
['Anaganaga Kadala', 'TELUGU', '2004', 'Devi Sri Prasad', 'licnf9o83yc', 'Venky', 0, 300],
['Chandelier', 'ENGLISH', '2014', 'Sia', '_Y5AkSz00W8', 'Voices'],
['Cruise', 'ENGLISH', '2012', 'Florida Georgia Line', 'Ioo9HXTE0kQ', "Here's to the Good Times", 0, 248],
['The Sign', 'ENGLISH', '1993', 'Ace of Base', 'NIPPh7AWSt4', 'Happy Nation', 0, 188],
['Kuch Kuch Hota Hain', 'HINDI', '1998', 'Jatin Lalit', '7Je-CLxkYVQ', 'Kuch Kuch Hota Hain', 0, 295],
['7G Rainbow Colony BGM 1', 'TAMIL', '2007', 'Yuvan Shankar Raja', 'j4ailMFItw0', '7G Rainbow Colony'],
      ],
    'informations': {}
  }
}

export default playlists;
